define("supplier-portal/mirage/serializers/purchase", ["exports", "supplier-portal/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    embed: true,
    include: request => {
      if (request.url.indexOf("/api/purchases/") !== -1) {
        return ["lines"];
      }

      return [];
    }
  });

  _exports.default = _default;
});