define("supplier-portal/components/o-recaptcha", ["exports", "supplier-portal/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["g-recaptcha"],
    attributeBindings: ["size:data-size", "sitekey:data-sitekey", "callback:data-callback"],
    size: "invisible",
    sitekey: _constants.CAPTCHA_KEY,

    didInsertElement() {
      this._super(...arguments);

      this.checkCaptcha();
    },

    checkCaptcha() {
      if (!window.grecaptcha) {
        Ember.run.later(this, () => this.checkCaptcha(), 500);
      } else {
        this.onReady();
      }
    },

    onReady() {
      const attrs = this.getProperties("size", "sitekey");
      const params = Ember.assign(attrs, {
        callback: this.onCallback.bind(this)
      });
      const id = window.grecaptcha.render(this.element, params);
      this.sendAction("recaptchaId", id);
      window.grecaptcha.execute(id);
    },

    onCallback() {}

  });

  _exports.default = _default;
});