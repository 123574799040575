define("supplier-portal/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CAPTCHA_KEY = _exports.EMAIL_REGEX = _exports.PASSWORD_REGEX = void 0;
  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  _exports.PASSWORD_REGEX = PASSWORD_REGEX;
  const EMAIL_REGEX = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
  _exports.EMAIL_REGEX = EMAIL_REGEX;
  const CAPTCHA_KEY = "6LfBwicUAAAAAElzExZ8SqmR_50T2mOnyr1rL9Ys";
  _exports.CAPTCHA_KEY = CAPTCHA_KEY;
});