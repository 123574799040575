define("supplier-portal/adapters/debitnote-summary", ["exports", "supplier-portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery(query) {
      return "/".concat(this.namespace, "/archives/debitnotes/per-month/").concat(query.date);
    },

    sortQueryParams() {
      return [];
    }

  });

  _exports.default = _default;
});