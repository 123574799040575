define("supplier-portal/controllers/index/quality/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Use global controller
    data: Ember.computed.alias('appCont.data'),
    appCont: Ember.inject.controller('application')
  });

  _exports.default = _default;
});