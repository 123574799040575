define("supplier-portal/models/notations-product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    comment_count: _emberData.default.attr('number'),
    product_image: _emberData.default.attr('string'),
    product_name: _emberData.default.attr('string'),
    evolution: _emberData.default.attr('number'),
    full_average: _emberData.default.attr('number'),
    full_comments: _emberData.default.attr('number'),
    full_notations: _emberData.default.attr('number'),
    reference: _emberData.default.attr('string'),
    shop: _emberData.default.belongsTo('shop', {
      inverse: null
    }),
    week_average: _emberData.default.attr('number'),
    week_comments: _emberData.default.attr('number'),
    week_notations: _emberData.default.attr('number')
  });

  _exports.default = _default;
});