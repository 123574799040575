define("supplier-portal/routes/index/accounting/archives/month/detail", ["exports", "moment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      return this.store.findRecord('payment-detail', params.reference, {
        adapterOptions: {
          date: this.paramsFor("index.accounting.archives.month").date
        }
      });
    },

    setupController() {
      this._super(...arguments);

      const month = this.paramsFor("index.accounting.archives.month").date;
      this.controller.setProperties({
        month: (0, _moment.default)(month).format("YYYY-MM-01"),
        reference: this.paramsFor(this.routeName).reference
      });
    }

  });

  _exports.default = _default;
});