define("supplier-portal/routes/preauth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller) {
      controller.preauth();
    }

  });

  _exports.default = _default;
});