define("supplier-portal/templates/index/legal-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HKwdeEMy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ol\",true],[10,\"class\",\"breadcrumb\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"active\"],[8],[1,[28,\"t\",[\"legal_notice.title\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"legal_notice.editor\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"otera\"],[8],[7,\"strong\",true],[8],[0,\"OTERA FRANCE\"],[9],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"SAS au capital de 200 000 €\\n    \"],[7,\"br\",true],[8],[9],[0,\"1 rue Louis Constant\\n    \"],[7,\"br\",true],[8],[9],[0,\"59650 Villeneuve d'Ascq\\n    \"],[7,\"br\",true],[8],[9],[0,\"Immatriculée au RCS de Lille Métropole sous le numéro 488 095 902\\n    \"],[7,\"br\",true],[8],[9],[0,\"Numéro de TVA : FR 65 488 095 902\\n    \"],[7,\"br\",true],[8],[9],[0,\"Responsable de publication : Loïs Abed-Liotard\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"legal_notice.host\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"otera\"],[8],[7,\"strong\",true],[8],[0,\"OTERA\"],[9],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"1 rue Louis Constant\\n    \"],[7,\"br\",true],[8],[9],[0,\"59650 Villeneuve d'Ascq\\n    \"],[7,\"br\",true],[8],[9],[0,\"webmaster@o-tera.com\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/index/legal-notice.hbs"
    }
  });

  _exports.default = _default;
});