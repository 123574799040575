define("supplier-portal/controllers/index/accounting/archives/month/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Use global controller
    data: Ember.computed.alias("appCont.data"),
    appCont: Ember.inject.controller('application'),

    /**
     * Get debit notes
     **/
    debitNotes: Ember.computed('model', function () {
      const operations = this.get('model.operations');
      return operations.filter(function (operation) {
        return operation.tag === 'debitnote' || operation.tag === 'debitnote_cancel';
      });
    }),

    /**
     * Get invoices
     **/
    invoices: Ember.computed('model', function () {
      return this.get('model.operations').filterBy('tag', 'invoice');
    }),

    /**
     * Get others
     **/
    others: Ember.computed('model', function () {
      const operations = this.get('model.operations');
      return operations.filter(function (operation) {
        return !(operation.tag === 'debitnote' || operation.tag === 'debitnote_cancel' || operation.tag === 'invoice');
      });
    })
  });

  _exports.default = _default;
});