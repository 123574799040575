define("supplier-portal/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cookie.default.extend({
    cookieExpirationTime: 60 * 60 * 24 * 30
  });

  _exports.default = _default;
});