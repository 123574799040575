define("supplier-portal/helpers/get-shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute: function ([shopId]) {
      const shop = this.store.peekRecord('shop', shopId);
      return shop.get('name');
    }
  });

  _exports.default = _default;
});