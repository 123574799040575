define("supplier-portal/controllers/password-reset", ["exports", "supplier-portal/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    queryParams: ["token"],
    password1: null,
    password2: null,

    /**
     * Return true if the password provided is valid :
     *
     * At least 8 chars,
     * with at least 1 number, 1 upper case letter and 1 lower case letter
     */
    checkPassword(password) {
      return _constants.PASSWORD_REGEX.test(password);
    },

    invalidPasswords: Ember.computed("password1", "password2", function () {
      const pwd1 = this.password1;
      const pwd2 = this.password2;
      return pwd1 !== pwd2 || !this.checkPassword(pwd1);
    }),
    actions: {
      changePassword() {
        const {
          password1,
          token
        } = this;
        this.session.authenticate("authenticator:token", password1, token).then(() => this.transitionToRoute("index"), () => {
          this.transitionToRoute("forgot-password", {
            queryParams: {
              reason: "expired-token"
            }
          });
        });
      }

    }
  });

  _exports.default = _default;
});