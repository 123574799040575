define("supplier-portal/components/o-administration-modal", ["exports", "supplier-portal/constants", "jquery"], function (_exports, _constants, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elementId: 'modal',
    content: null,
    notification: null,

    didUpdate() {
      this._super(...arguments);
    },

    checkEmail(email) {
      return _constants.EMAIL_REGEX.test(email);
    },

    invalidEmail: Ember.computed("notification", function () {
      return this.notification === null || this.get("notification.length") === 0 || !this.checkEmail(this.notification);
    }),

    resetValue() {
      this.set("notification", null);
    },

    close() {
      (0, _jquery.default)("div").modal("hide");
      this.resetValue();
    },

    actions: {
      changePassword() {
        this.sendAction("changePassword");
        this.close();
      },

      addEmail() {
        const value = this.element.querySelector("#email").value.trim();
        this.sendAction('addNotification', value);
        this.close();
      },

      resetValue() {
        this.resetValue();
      }

    }
  });

  _exports.default = _default;
});