define("supplier-portal/serializers/account", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: "login",
    serializeIntoHash: function (hash, type, record, options) {
      Ember.assign(hash, this.serialize(record, options));
    }
  });

  _exports.default = _default;
});