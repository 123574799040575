define("supplier-portal/mirage/factories/purchase", ["exports", "ember-cli-mirage", "faker", "moment"], function (_exports, _emberCliMirage, _faker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    reference() {
      return ("00000" + _faker.default.random.number()).slice(-6);
    },

    delivery_date() {
      return (0, _moment.default)(_faker.default.date.future(0, new Date())).format("YYYY-MM-DDTHH:mm:ss");
    },

    purchase_amount() {
      return _faker.default.random.number({
        min: 10000
      });
    },

    purchase_date() {
      return (0, _moment.default)(_faker.default.date.past(0, new Date())).format("YYYY-MM-DDTHH:mm:ss");
    },

    state() {
      return _faker.default.random.arrayElement(["approved", "confirmed"]);
    },

    validator() {
      return _faker.default.name.findName();
    },

    comment() {
      return _faker.default.lorem.sentence();
    },

    afterCreate(purchase, server) {
      server.createList('purchase-line', 3, {
        purchase
      });
    }

  });

  _exports.default = _default;
});