define("supplier-portal/templates/components/o-archive-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YGtPvFE9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"tile\"]],\"purchase.month\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"head\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[1,[24,[\"purchase\",\"shop\",\"name\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[1,[28,\"format-date\",[[24,[\"purchase\",\"date\"]]],[[\"day\",\"month\"],[\"numeric\",\"long\"]]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"total\"],[8],[1,[28,\"format-number\",[[24,[\"purchase\",\"amount\"]]],[[\"format\"],[\"amount\"]]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"purchase\",\"supplier_reference\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"status done\"],[8],[1,[24,[\"purchase\",\"supplier_reference\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"status waiting\"],[8],[1,[28,\"t\",[\"purchases.archives.tile.waiting\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"tile\"]],\"debitnote.archives\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"head\"],[8],[1,[22,\"date\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[1,[22,\"content\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"head\"],[8],[1,[22,\"date\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[1,[22,\"content\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"total\"],[8],[1,[22,\"total\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/components/o-archive-tile.hbs"
    }
  });

  _exports.default = _default;
});