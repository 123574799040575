define("supplier-portal/mirage/factories/purchase-line", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    reference() {
      return ("00000" + _faker.default.random.number()).slice(-6);
    },

    package_number() {
      return 0;
    },

    purchased_quantity() {
      return _faker.default.random.number({
        min: 1,
        max: 100
      });
    },

    purchased_price() {
      return _faker.default.random.number({
        max: 50
      }) * 100;
    },

    amount() {
      return this.quantity * this.purchased_price;
    },

    product() {
      const name = _faker.default.commerce.product();

      const unit = _faker.default.random.arrayElement(["Pce", "Kg"]);

      return {
        customer_name: name,
        supplier_name: name,
        package_name: "Colis (20 Pce)",
        package_quantity: _faker.default.random.number({
          max: 50
        }),
        stock_unit: unit,
        weight_unit: unit === "Kg"
      };
    },

    proposed_quantity() {
      return this.purchased_quantity + 1;
    },

    proposed_price() {
      return this.purchased_price + 100;
    }

  });

  _exports.default = _default;
});