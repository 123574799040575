define("supplier-portal/controllers/index/comments/index", ["exports", "jquery", "ember-group-by"], function (_exports, _jquery, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortProperties: ['notation_day:desc', 'purchase_date:desc'],
    untraitedComments: Ember.computed.filter('model.@each.{supplier_read,supplier_answer}', function (obj) {
      return !obj.supplier_read;
    }),
    sortedComments: Ember.computed.sort('untraitedComments', 'sortProperties'),
    actions: {
      setAnswer(comment, answer, defer) {
        const data = {
          supplier_answer: answer
        };
        const id = encodeURIComponent(comment.get("id"));

        _jquery.default.ajax({
          method: 'POST',
          url: "/api/v2/comments/".concat(id),
          headers: {
            'Content-type': 'application/json'
          },
          data: JSON.stringify(data)
        }).done(() => defer.resolve()).fail(() => defer.reject());
      },

      setRead(comment, defer) {
        const id = encodeURIComponent(comment.get("id"));
        const data = {
          supplier_read: true
        };

        _jquery.default.ajax({
          method: 'PUT',
          url: "/api/v2/comments/".concat(id),
          headers: {
            'Content-type': 'application/json'
          },
          data: JSON.stringify(data)
        }).done(() => defer.resolve()).fail(() => defer.reject());
      }

    }
  });

  _exports.default = _default;
});