define("supplier-portal/controllers/index/accounting/index", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selection: null,
    actions: {
      focusLoadedPayment() {
        const timelineEntries = (0, _jquery.default)('.events-wrapper ol li');
        const selection = this.selection;
        let target;

        if (!selection) {
          timelineEntries.find("a").removeClass("selected");
          target = timelineEntries.eq(0).children("a");
          this.replaceRoute("index.accounting.index.pending", (0, _moment.default)(this.model.get("firstObject.date")).format("YYYY-MM-DD"));
        } else {
          target = timelineEntries.find("[data-id=\"".concat(selection, "\"]"));
        }

        target.addClass("selected");
      },

      loadPayment(date) {
        this.replaceRoute("index.accounting.index.pending", date);
      }

    }
  });

  _exports.default = _default;
});