define("supplier-portal/mirage/serializers/debitnote-summary-detail", ["exports", "supplier-portal/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForModel() {
      return "debitnote";
    }

  });

  _exports.default = _default;
});