define("supplier-portal/serializers/invoice", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeFindRecordResponse(store, primaryModelClass, payload) {
      const id = "".concat(payload.invoice.shop, "/").concat(payload.invoice.reference);
      payload.invoice.id = id;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});