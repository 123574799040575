define("supplier-portal/controllers/index/purchases/archives/month/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Use global controller
    data: Ember.computed.alias("appCont.data"),
    appCont: Ember.inject.controller('application'),
    queryParams: ['ref'],
    ref: null,
    filteredPurchases: Ember.computed('ref', 'model', function () {
      const shop = this.ref;
      const purchases = this.get("model.purchases");

      if (shop) {
        return purchases.filterBy("shop.reference", shop);
      }

      return purchases;
    }),
    actions: {
      filterPurchases(shop) {
        const route = "index.purchases.archives.month.index";
        const queryParams = {
          ref: null
        };

        if (shop) {
          queryParams.ref = shop;
        }

        this.replaceRoute(route, {
          queryParams: queryParams
        });
      }

    }
  });

  _exports.default = _default;
});