define("supplier-portal/routes/index/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return Ember.RSVP.hash({
        account: this.store.queryRecord("account", {}),
        purchases: this.store.peekAll("purchase"),
        payments: this.store.findAll("payment"),
        debitnotes: this.store.findAll("debitnote"),
        comments: this.store.findAll("comment").then(results => results.filter(comments => {
          return comments.get("supplier_read") === false;
        })),
        shops: this.get("store").peekAll("shop")
      });
    }

  });

  _exports.default = _default;
});