define("supplier-portal/authenticators/preauth", ["exports", "supplier-portal/authenticators/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    /**
     * Authenticate automatically
     */
    authenticate(login, otp, readonly, key) {
      return new Ember.RSVP.Promise(async (resolve, reject) => {
        try {
          var url = "/api/v2/preauth?login=".concat(login);
          url = url + "&otp=".concat(otp, "&readonly=").concat(readonly, "&key=").concat(key);
          const response = await fetch(url, {
            credentials: 'same-origin'
          });

          if (response.ok) {
            resolve({
              cacheid: Math.random().toString(36).slice(2),
              readonly: "1"
            });
          } else {
            reject(response.status);
          }
        } catch (e) {
          reject();
        }
      });
    }

  });

  _exports.default = _default;
});