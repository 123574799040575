define("supplier-portal/controllers/index/administration/index", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Use global controller
    data: Ember.computed.alias('appCont.data'),
    appCont: Ember.inject.controller('application'),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    isReadOnly: Ember.computed.equal('session.data.authenticated.readonly', '1'),

    confirmSuccess() {
      this.notify.success(this.intl.t("administration.update_data_success"), {
        closeAfter: 3000
      });
    },

    actions: {
      changePassword() {
        _jquery.default.ajax({
          url: "/api/v2/password-lost",
          method: "POST"
        });
      },

      addNotification(newNotification) {
        const notification = {
          value: newNotification.toLowerCase().trim(),
          type: newNotification.indexOf("@") !== -1 ? "email" : "sms"
        };
        const notifications = this.model.get('notifications').addObject(notification);
        this.model.set('notifications', notifications);
        this.model.save().then(() => this.confirmSuccess());
      },

      removeNotification(notification) {
        const notifs = this.get("model.notifications");
        const len = notifs.length;

        for (let i = 0; i < len; ++i) {
          if (notifs[i].value === notification) {
            notifs.removeAt(i);
            this.model.set("notifications", notifs);
            this.model.save().then(() => this.confirmSuccess());
            break;
          }
        }
      }

    }
  });

  _exports.default = _default;
});