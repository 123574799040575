define("supplier-portal/models/comment", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    reference: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    customer: _emberData.default.attr('string'),
    purchase_date: _emberData.default.attr('o-date'),
    notation_date: _emberData.default.attr('o-date'),
    product: _emberData.default.attr('string'),
    product_image: _emberData.default.attr('string'),
    product_reference: _emberData.default.attr('number'),
    shop: _emberData.default.belongsTo('shop', {
      inverse: null
    }),
    shop_answer: _emberData.default.attr('string'),
    supplier_answer: _emberData.default.attr('string'),
    supplier_read: _emberData.default.attr(),
    value: _emberData.default.attr('number'),
    notation_day: Ember.computed('notation_date', function () {
      return (0, _moment.default)(this.notation_date).format("YYYY-MM-DD");
    })
  });

  _exports.default = _default;
});