define("supplier-portal/components/o-timeline", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    _eventsDistanceSameDay: 55,
    _eventsDistance: 110,
    _timelineWrapper: null,
    _eventsWrapper: null,
    _timelineEvents: null,
    _dates: null,
    _timelineNavigation: null,

    didInsertElement() {
      this._super(...arguments);

      this.initTimeline();
      window.addEventListener("orientationchange", () => this.initTimeline());
      const mc = new Hammer.Manager(this.element.querySelector(".events-wrapper"), {
        touchAction: "pan-y"
      });
      mc.add(new Hammer.Swipe());
      mc.on("swipe", e => {
        if (e.direction === Hammer.DIRECTION_RIGHT) {
          this.updateSlide("prev");
        } else if (e.direction === Hammer.DIRECTION_LEFT) {
          this.updateSlide("next");
        }
      });
    },

    didUpdate() {
      this.initTimeline();
    },

    /**
     * Build the timeline
     */
    initTimeline() {
      if (!this.model || this.get("model.length") === 0) {
        return;
      }

      const timeline = (0, _jquery.default)(this.element.querySelector('.timeline')); //cache timeline components

      this._timelineWrapper = timeline.find('.events-wrapper');
      this._eventsWrapper = this._timelineWrapper.children('.events');
      this._timelineEvents = this._eventsWrapper.find('a');
      this._dates = this.model.getEach(this.datePath);
      this._timelineNavigation = timeline.find('.timeline-navigation'); //assign a left postion to the single events along the timeline

      this.setDatePosition(); //assign a width to the timeline

      this._timelineWidth = this.setTimelineWidth(); //detect click on the next arrow

      this._timelineNavigation.on('click', '.next', event => {
        event.preventDefault();
        this.updateSlide('next');
      }); //detect click on the prev arrow


      this._timelineNavigation.on('click', '.prev', event => {
        event.preventDefault();
        this.updateSlide('prev');
      }); //detect click on the a single event - show new event content


      this._eventsWrapper.on('click', 'a', event => {
        const node = (0, _jquery.default)(event.currentTarget);
        event.preventDefault();

        this._timelineEvents.removeClass('selected');

        node.addClass('selected');
        this.sendAction("loadItem", node.data("id"));
      }); // manage next button displaying


      if (this._eventsWrapper.width() > this._timelineWrapper.width()) {
        this._timelineNavigation.find('.next').show();
      }

      this.sendAction("focusLoadedItem");
    },

    /**
     * Update the slider
     */
    updateSlide(string) {
      const value = this.getTranslateValue();

      const width = this._timelineWrapper.width(); //translate the timeline to the left('next')/right('prev')


      if (string === 'next') {
        this.translateTimeline(value - width + this._eventsDistanceSameDay, width - this._timelineWidth);
      } else {
        this.translateTimeline(value + width - this._eventsDistanceSameDay);
      }
    },

    /**
     * Translate the timeline
     */
    translateTimeline(value, totWidth) {
      value = value > 0 ? 0 : value; //only negative translate value
      //do not translate more than timeline width

      value = totWidth !== undefined && value < totWidth ? totWidth : value;

      this._eventsWrapper.css("transform", "translateX(".concat(value, "px)"));

      if (value === 0) {
        this._timelineNavigation.find('.prev').hide();
      } else {
        this._timelineNavigation.find('.prev').show();
      }

      if (value === totWidth) {
        this._timelineNavigation.find('.next').hide();
      } else {
        this._timelineNavigation.find('.next').show();
      }
    },

    /**
     * Update timeline position
     */
    updateTimelinePosition(string, event) {
      //translate timeline to the left/right according to the position of the selected event
      const eventLeft = event.position().left;

      const timelineWidth = this._eventsWrapper.width();

      const timelineTranslate = this.getTranslateValue();

      if (string === 'next' && eventLeft > timelineWidth - timelineTranslate || string === 'prev' && eventLeft < -timelineTranslate) {
        this.translateTimeline(-eventLeft + timelineWidth / 2, timelineWidth - timelineWidth);
      }
    },

    /**
     * Count the timeline width
     */
    setTimelineWidth() {
      let totalWidth = 120;

      const timelineWrapperWidth = this._timelineWrapper.width();

      for (let i = 0; i < this._dates.length - 1; ++i) {
        if (this.isSameDay(this._dates[i], this._dates[i + 1])) {
          totalWidth += this._eventsDistanceSameDay;
        } else {
          totalWidth += this._eventsDistance;
        }
      }

      totalWidth = Math.max(totalWidth, timelineWrapperWidth);

      this._eventsWrapper.css('width', totalWidth);

      let target;

      if (this._eventsWrapper.find('a.selected').length) {
        target = this._eventsWrapper.find('a.selected');
      } else {
        target = this._eventsWrapper.find('a').first();
      }

      this.updateTimelinePosition('next', target);
      return totalWidth;
    },

    /**
     * Set dates position correctly
     */
    setDatePosition() {
      let offset = 0;

      for (let i = 1; i < this._dates.length; ++i) {
        if (this.isSameDay(this._dates[i - 1], this._dates[i])) {
          offset += this._eventsDistanceSameDay;
        } else {
          offset += this._eventsDistance;
        }

        this._timelineEvents.eq(i).css('left', offset);
      }
    },

    /**
     * Return true if date1 and date2 is the same day
     */
    isSameDay(date1, date2) {
      return date1.toDateString() === date2.toDateString();
    },

    /**
     * Translate
     */
    getTranslateValue() {
      let translateValue = 0;

      let timelineTranslate = this._eventsWrapper.css("transform") || this._eventsWrapper.css("-ms-transform");

      if (timelineTranslate.indexOf('(') >= 0) {
        timelineTranslate = timelineTranslate.split('(')[1];
        timelineTranslate = timelineTranslate.split(')')[0];
        timelineTranslate = timelineTranslate.split(',');
        translateValue = timelineTranslate[4];
      }

      return Number(translateValue);
    }

  });

  _exports.default = _default;
});