define("supplier-portal/routes/index/quality/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const shops = this.store.peekAll('shop');
      return Ember.RSVP.hash({
        summary: this.store.queryRecord('notations-summary', {}),
        bestNotation: this.store.queryRecord('notations-product', {
          type: 'best'
        }),
        worstNotation: this.store.queryRecord('notations-product', {
          type: 'worst'
        }),
        alerts: this.store.query('notations-product', {
          type: 'alerts'
        }),
        shops: Ember.RSVP.allSettled(shops.map(shop => this.store.findRecord('notations-shop', shop.get('reference')))).then(result => {
          // Filter rejected promises
          return result.filter(promise => promise.state === 'fulfilled').map(promise => promise.value);
        }, function (error) {
          /* Don't crash when shop is not accessible */

          /* eslint-disable no-console */
          console.log(error);
          /* eslint-enable no-console */
        }),
        bestComment: this.store.queryRecord('comment', {
          type: 'best'
        }),
        worstComment: this.store.queryRecord('comment', {
          type: 'worst'
        })
      });
    }

  });

  _exports.default = _default;
});