define("supplier-portal/mirage/factories/purchases-summary", ["exports", "ember-cli-mirage", "faker", "moment"], function (_exports, _emberCliMirage, _faker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    count() {
      return _faker.default.random.number({
        max: 9
      });
    },

    month(i) {
      return (0, _moment.default)(new Date()).subtract(i, "month").format("YYYY-MM-01");
    },

    total() {
      return _faker.default.random.number({
        max: 9999
      }) * this.count;
    },

    afterCreate(obj, server) {
      const date = obj.month.substring(0, 8) + ("0" + parseInt(Math.random() * 27 + 1)).slice(-2);
      server.createList("purchase-summary", 6, {
        shop: "001",
        date: date
      });
      server.createList("purchase-summary", 7, {
        shop: "003",
        date: date
      });
      server.createList("purchase-summary", 3, {
        shop: "004",
        date: date
      });
      server.createList("purchase-summary", 4, {
        shop: "005",
        date: date
      });
    }

  });

  _exports.default = _default;
});