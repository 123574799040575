define("supplier-portal/routes/index/quality/product/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const reference = this.paramsFor('index.quality.product').reference;
      const splitedRef = reference.split('/');
      return Ember.RSVP.hash({
        shop: splitedRef[0],
        comments: this.store.query('comment-archive', {
          reference: reference,
          type: 'archive'
        }),
        product: this.store.findRecord('notations-shop', splitedRef[0]).then(result => {
          const products = result.data.products;
          const len = products.length;

          for (let i = 0; i < len; i++) {
            if (products[i].id === reference) {
              return products[i];
            }
          }
        })
      });
    },

    resetController(controller, isExisting) {
      if (isExisting) {
        controller.set('filter', '');
      }
    }

  });

  _exports.default = _default;
});