define("supplier-portal/serializers/purchase-line", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: "reference",

    payloadKeyFromModelName() {
      return "purchase";
    },

    serialize() {
      const json = this._super(...arguments);

      return {
        lines: [{
          line_reference: json.reference,
          proposed_quantity: json.proposed_quantity,
          proposed_price: json.proposed_price
        }]
      };
    }

  });

  _exports.default = _default;
});