define("supplier-portal/routes/index/debitnotes/archives/month/index", ["exports", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return Ember.RSVP.hash({
        shops: this.store.peekAll('shop'),
        debitnotes: this.store.query('debitnote-summary', {
          date: this.paramsFor('index.debitnotes.archives.month').date
        }).then(debitnotes => debitnotes.sortBy('term_date', 'date'))
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('date', this.paramsFor('index.debitnotes.archives.month').date);
    },

    resetController(controller) {
      controller.set("ref", null);
    },

    actions: {
      didTransition() {
        Ember.run.next(() => (0, _jquery.default)('.selectpicker').selectpicker());
      }

    }
  });

  _exports.default = _default;
});