define("supplier-portal/translations/fr-fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accounting": {
      "archives": {
        "allshops": "Tous les magasins",
        "detail": {
          "debit_notes_title": "Notes de débit",
          "invoices_title": "Factures",
          "others_title": "Autres opérations associées",
          "summary": {
            "date": "en date du",
            "reference": "qui a pour référence",
            "regulation": "Règlement de {amount}",
            "shop": "par"
          },
          "summary_title": "Résumé",
          "table": {
            "amount": "Montant",
            "associated_invoice": "Facture associée",
            "date": "Date",
            "label": "Libellé",
            "otera_reference": "Référence O'tera",
            "reference": "Référence",
            "type": "Type",
            "types": "{tag, select,\n  payment {Règlement}\n  debitnote_cancel {Note de débit annulée}\n  other {autre}\n}\n"
          }
        },
        "no_entry": "Aucun paiement",
        "no_entry_shop": "Aucun paiement pour {shop}",
        "regulation_pluralization": "{value, plural,\n  =0 {Aucun règlement}\n  =1 {# règlement}\n  other {# règlements}\n}\n",
        "title": "Historique des règlements"
      },
      "detail": {
        "debit_notes_title": "Notes de débit",
        "invoices_title": "Factures",
        "summary": {
          "date": "en date du",
          "regulation": "Règlement de {amount}"
        },
        "summary_title": "Résumé",
        "table": {
          "amount": "Montant",
          "date": "Date",
          "reference": "Référence"
        }
      },
      "payments": {
        "no_entry": "Aucun paiement prévu"
      },
      "tile": {
        "download_ledger": "Télécharger le grand livre de compte"
      },
      "title": "Règlements à venir"
    },
    "administration": {
      "add_email_notification": "Ajouter un email",
      "address": "Adresse",
      "change_password": "Modifier le mot de passe",
      "contact_information": "Mes informations",
      "email": "Email",
      "login": "Identifiant",
      "modal": {
        "change_password_message": "Vous allez recevoir un email avec un lien pour modifier votre mot de passe.\n",
        "confirm": "Confirmation du mot de passe",
        "email": "Adresse email",
        "password": "Mot de passe",
        "phone": "Numéro de téléphone",
        "title": "{field, select,\n  password {Modifier le mot de passe}\n  notification_email {Ajouter une notification par email}\n  other {Administration}\n}\n"
      },
      "name": "Société",
      "notifications": "M'avertir qu'une nouvelle commande est disponible",
      "phone": "Téléphone",
      "readonly": "Lecture seule, action non disponible",
      "siret": "Siret",
      "title": "Administration",
      "town": "Ville",
      "update_data": "Pour mettre à jour ces informations, merci de vous rapprocher de votre magasin.",
      "update_data_success": "Modification effectuée",
      "update_password": "Mettre à jour le mot passe",
      "zip": "Code postal"
    },
    "button": {
      "download": "Télécharger",
      "download_flow": "Télécharger le flux de commande",
      "more": "Plus d'informations...",
      "purchase": {
        "cancel": "Annuler les modifications",
        "validate": "Valider la commande"
      }
    },
    "comment_readonly": {
      "answers": "Réponse apportée par",
      "congratulation": "Félicitation ! Vous n'avez aucun avis négatif !",
      "no_answer": "Aucune réponse",
      "no_bad_comments": "Félicitation ! Vos produits n'ont aucun avis négatifs sur ces 7 derniers jours",
      "no_comments": "Aucun de vos produits n'a été commenté ces 7 derniers jours",
      "no_good_comments": "Attention ! Vos produits n'ont aucun avis positif sur ces 7 derniers jours",
      "purchase_of": "Achat du {date}",
      "supplier": "Vous",
      "supplier_message": "Vous avez répondu"
    },
    "comments": {
      "alert": {
        "average_rating": "Note moyenne :",
        "last_week": "Note moyenne S-1 :",
        "number_rating": "Nombre de notes :"
      },
      "comment": {
        "answer": "Répondre",
        "error": "Une erreur est survenue durant la validation",
        "purchase_of": "{date}",
        "read": "Marquer comme lu",
        "show": "Voir",
        "supplier_author": "Vous avez répondu",
        "valid": "Valider"
      },
      "no_entry": "Aucun commentaire n'a été écrit récemment",
      "readonly": "Lecture seule, action non disponible",
      "tile": {
        "average_rating": "Note moyenne",
        "supplier_untraited": "à traiter",
        "untraited_pluralization": "{value, plural,\n  =0 {Aucun commentaire}\n  =1 {# commentaire}\n  other {# commentaires}\n}\n"
      },
      "title": "Commentaires"
    },
    "dashboard": {
      "communication": {
        "comments": {
          "text": "<strong>Véritable mine d'informations</strong>, les commentaires issus de notre système de notations vous sont mis à disposition. Ces commentaires vous permettent d'avoir <strong>une visibilité directe et quasi instantanée sur la qualité de vos produits</strong> et l'adhésion à ces derniers par les consommateurs. <br /><br /><strong>Nous comptons sur vous pour participer et répondre directement à <del>nos</del> vos clients</strong>. Une réponse, une explication, un merci de votre part a <strong>une énorme valeur</strong> pour eux et permet de <strong>faire résonner le circuit-court</strong>.",
          "title": "Commentaires"
        },
        "debitnotes": {
          "text": "Ce module regroupe l’ensemble des documents vous permettant de visualiser et de comprendre les notes de débit émises par les magasins. Il est composé : <br /><br /><strong>Des notes de débit à venir : </strong>celles-ci n'ont pas encore été débitées car la date d'échéance n'est pas passée. Elles sont séparées en deux parties en fonction qu'elles proviennent d'un souci de « qualité produit » ou « d'une différence de facturation ». <br /><br /><strong>De l'historique des notes de débit</strong> : classées mois par mois, vous retrouverez le détail de vos notes de débit et pourrez en un coup d'oeil avoir une visibilité pour tous les magasins avec lesquels vous travaillez.",
          "title": "Notes de débit"
        },
        "payments": {
          "text": "Ce module vous permet de <strong>cadrer facilement les différents règlements</strong> en ayant le détail de leur contenu (factures / notes de débit). Pour cela rendez vous dans la rubrique historique des règlements. <br /><br />Il permet également d'avoir <strong>une vision sur les différents règlements à venir</strong> afin de vous faciliter la gestion de votre trésorerie. <br /><br />Vous y trouverez aussi votre grand livre de compte exploitable au format « Excel ».",
          "title": "Réglements"
        },
        "purchases": {
          "text": "Dans cette partie de l'outil, vous trouverez l'<strong>ensemble des commandes émises par nos magasins</strong>. <br /><br /><strong>Nous comptons sur vous pour valider les commandes dès que vous les avez prises en compte.</strong> Cela nous permettra d'être alertés en cas de soucis sur une commande et donc de vous contacter. <br /><br /><strong>Vous pouvez également apporter des modifications sur les prix ainsi que les quantités</strong> que vous allez nous livrer : cela permet entre autres d'accélérer la livraison.",
          "title": "Commandes"
        },
        "quality": {
          "text": "<strong>La qualité de vos produits</strong> est la première raison d'adhésion de nos clients ! Véritable <strong>outil de suivi qualité</strong>, ce module vous permettra de prendre des <strong>décisions rapides au quotidien</strong> afin de garantir une <strong>qualité optimale</strong> sur l'ensemble de vos produits. <br /><br />En temps réel, sans aucun filtre, l'ensemble des avis clients concernant vos produits est mis en avant ! Ces informations sont <strong>la clé de voûte de notre succès commercial commun</strong>. <br /><br />Concrètement, vous retrouverez : <ul> <li>la note moyenne sur les 7 derniers jours de l'ensemble de vos produits</li> <li>l'évolution de cette note sur les 7 derniers jours</li> <li>la note moyenne de vos produits sur les 28 derniers jours</li> <li>l'ensemble des commentaires depuis 28 jours</li> </ul> Envie de savoir ce que les clients pensent de vos produits ? <strong>Pensez à consulter de manière régulière</strong> ce module !",
          "title": "Qualité"
        },
        "title": "Bienvenue sur l'espace partenaire"
      },
      "project": {
        "title": "MANGEONS RESPONSABLE !",
        "video": "Votre navigateur ne supporte pas les vidéos HTML5."
      },
      "tile": {
        "comments_untraited": "à traiter",
        "comments_untraited_pluralization": "{value, plural,\n  =0 {Aucun commentaire}\n  =1 {# commentaire}\n  other {# commentaires}\n}\n",
        "next_payment": "Prochain paiement",
        "no_next_payment_1": "Aucun paiement",
        "no_next_payment_2": "à venir",
        "not_delivered_pluralization": "{value, plural,\n  =0 {non délivrée}\n  =1 {non délivrée}\n  other {non délivrées}\n}\n",
        "pending_debitnote": "en cours",
        "pending_debitnote_pluralization": "{value, plural,\n  =0 {Aucune note de débit}\n  =1 {# note de débit}\n  other {# notes de débit}\n}\n",
        "purchase_pluralization": "{value, plural,\n  =0 {Aucune commande}\n  =1 {# commande}\n  other {# commandes}\n}\n"
      }
    },
    "debitnotes": {
      "archives": {
        "allshops": "Tous les magasins",
        "debitnote_pluralization": "{value, plural,\n  =0 {Aucune note de débit}\n  =1 {# note de débit}\n  other {# notes de débit}\n}\n",
        "no_entry": "Aucune note de débit",
        "no_entry_shop": "Aucune note de débit pour {shop}",
        "title": "Historique des notes de débit"
      },
      "no_entry": "Aucune note de débit",
      "no_entry_shop": "Aucune note de débit pour {shop}",
      "table": {
        "amount": "Montant",
        "comment": "Commentaire",
        "date": "Date de création",
        "document": "Document",
        "shop": "Magasin",
        "term_date": "Date d'échéance"
      },
      "tile": {
        "debitnote_pluralization": "{value, plural,\n  =0 {Aucune note de débit}\n  =1 {# note de débit}\n  other {# notes de débit}\n}\n",
        "invoice": "Différence de facturation",
        "quality": "Qualité produit"
      },
      "title": "Notes de débit à venir",
      "types": "{value, select,\n  supplier_breakage {Qualité produit}\n  delivery {Différences de quantités}\n  invoice {Différences de prix}\n  other {{value}}\n}\n"
    },
    "document_modal": {
      "debitnote_types": "{value, select,\n  supplier_breakage {Qualité produit}\n  delivery {Différence de facturation}\n  invoice {Différence de facturation}\n  cancel {Annulée}\n  other {{value}}\n}\n",
      "section": {
        "amount": "Montant",
        "comment": "Commentaire",
        "date": "Date",
        "detail": "Détail",
        "image_pluralization": "{value, plural,\n  =1 {Photo}\n  other {Photos}\n}\n",
        "reference": "Référence",
        "type": "Type"
      },
      "table": {
        "amount": "Montant",
        "price": "Prix",
        "product": "Produit",
        "quantity": "Quantité",
        "reference": "Référence",
        "taxed_total_amount": "Montant Total TTC",
        "total_amount": "Montant Total",
        "untaxed_total_amount": "Montant Total HT"
      },
      "title": {
        "debitnote": "Note de débit {reference}",
        "invoice": "Facture {reference}",
        "purchase": "Commande {reference}"
      }
    },
    "error": {
      "detail": "Une erreur est survenue",
      "goback": "Cliquez ici pour retourner au tableau de bord",
      "shop_unavailable": "Attention, toutes les données du magasin {shop} n'ont pu être chargées. Merci d'essayer ultérieurement.\n",
      "upgrade_link": "Cliquez ici pour recharger la page",
      "upgrade_needed": "La version de l'application est obsolète"
    },
    "footer": {
      "contact": "Contact",
      "legal_notice": "Mentions légales",
      "slogan": "LE MARCHÉ DU FRAIS"
    },
    "layout": {
      "accounting": "Règlements",
      "administration": "Administration",
      "analysis": "Analyse",
      "comments": "Commentaires",
      "dashboard": "Tableau de bord",
      "debitnotes": "Notes de débit",
      "logout": "Déconnexion",
      "menu_label": "Bouton menu",
      "mobile": "Application mobile",
      "my_account": "Mon compte",
      "purchases": "Commandes",
      "quality": "Qualité",
      "website_title": "ESPACE PARTENAIRES"
    },
    "legal_notice": {
      "editor": "Editeur",
      "host": "Hébergeur",
      "title": "Mentions légales"
    },
    "loading": "Chargement en cours...",
    "login": {
      "account_not_found": "Identifiant non trouvé",
      "bad_credentials": "Identifiant ou mot de passe incorrect",
      "error": "Une erreur est survenue",
      "expired_token": "La session a expiré, veuillez recommencer le processus",
      "forgot_password": "Mot de passe ou identifiant oublié ?",
      "goback": "Retour",
      "password": "Mot de passe",
      "password_requirements": "Le mot de passe doit contenir au moins 8 caractères, une lettre minuscule, une lettre majuscule et un chiffre.\n",
      "password_sent": "Un email contenant un nouveau mot de passe a été envoyé à <strong>{email}</strong>",
      "protected_page": "La page est protégée et veuillez vous connecter",
      "repeat_password": "Répéter le mot de passe",
      "reset_password": "Réinitialisation du mot de passe",
      "title": "Bienvenue sur l'espace partenaires",
      "uid_or_email_or_siret": "Identifiant, email ou SIRET",
      "username": "Identifiant",
      "valid": "Valider"
    },
    "mobile": {
      "connection": {
        "button": {
          "generate": "Obtenir un nouveau code",
          "remove": "Revenir à l'étape 1"
        },
        "expired": "Le code a expiré",
        "explain_1": "Saisissez le code",
        "explain_2": "sur l'écran de connexion de l'application",
        "readonly": "Vous êtes actuellement en lecture seule. Voici un aperçu de l'interface présentée aux partenaires.",
        "subtitle": "2. Connexion"
      },
      "install_app": {
        "button": "C'est fait, passons à la suite",
        "qrcode": "Téléchargez l'application<br />en scannant ce QrCode",
        "stores": "Vous utilisez votre smartphone ?<br />sélectionnez votre store",
        "subtitle": "1. Installation"
      },
      "title": "Application mobile",
      "use": {
        "button": "Installer l'application sur un autre appareil",
        "content_1": "L'installation s'est terminée avec succés !",
        "content_2": "L'application mobile sera régulièrement mise à jour pour vous apporter toujours plus de fonctionnalités.<br /><br />N'hésitez pas à nous faire part de vos idées pour améliorer cet outil !",
        "subtitle": "3. Terminé"
      }
    },
    "modal": {
      "cancel": "Annuler",
      "close": "Fermer",
      "purchase": {
        "cancel": "Annuler toutes les modifications de la commande ?",
        "validation": "Confirmer la validation de la commande ?"
      },
      "valid": "Valider"
    },
    "purchases": {
      "archives": {
        "allshops": "Tous les magasins",
        "no_entry": "Aucune commande",
        "no_entry_shop": "Aucune commande faite par {shop}",
        "tile": {
          "waiting": "Facture en attente"
        },
        "title": "Historique des commandes"
      },
      "head": "{value, select,\n  cancel {Commande annulée}\n  done {Commande livrée}\n  confirmed {Commande à valider}\n  approved {Commande validée}\n  other {Commande}\n}\n",
      "header": {
        "amount": "Montant : {amount} HT",
        "comment": "Commentaire",
        "date": "Livraison le {date}",
        "validator": "Validé par {validator}"
      },
      "no_entry": "Aucune commande à venir",
      "readonly": "Lecture seule, action non disponible",
      "table": {
        "amount": "Total",
        "field_required": "Ce champ est requis",
        "packaging": "Colisage",
        "price": "Prix",
        "price_change": "Modifier le prix ({value})",
        "price_error": "Veuillez entrer une valeur pour le prix",
        "product": "Produit",
        "quantity": "Quantité",
        "quantity_change": "Modifier la quantité ({value})",
        "quantity_error": "Veuillez entrer une valeur pour la quantité",
        "quantity_no_fraction_digits_unit": "Pce"
      },
      "tile": {
        "delivered_next_days": "à livrer dans les prochains jours",
        "delivered_today": "à livrer aujourd'hui",
        "purchase_pluralization": "{value, plural,\n  =0 {Aucune commande}\n  =1 {# commande}\n  other {# commandes}\n}\n",
        "to_validate": "à valider"
      },
      "title": "Commandes à venir",
      "update_line_confirmation": "Mise à jour effectuée",
      "validation_confirmation": "Commande validée"
    },
    "quality": {
      "best_comment": "Le meilleur avis client",
      "best_product": "Votre produit le plus apprécié",
      "comments_pluralization": "{value, plural,\n  =0 {Aucun commentaire}\n  =1 {# commentaire}\n  other {# commentaires}\n}\n",
      "current_week": "Au global (sur les 7 derniers jours)",
      "info": "Informations générales",
      "no_bad_notations": "Félicitation ! Tous vos produits ont une note moyenne correcte",
      "no_good_notations": "Attention ! Aucun de vos produits n'a été apprécié ces 7 derniers jours",
      "no_notations": "Aucun produit n'a été noté ces 7 derniers jours",
      "notations_pluralization": "{value, plural,\n  =0 {Aucune note}\n  =1 {# note}\n  other {# notes}\n}\n",
      "per_shop": "Par magasin (sur les 7 derniers jours)",
      "product_alert_pluralization": "{value, plural,\n  =1 {Attention, vous avez #  produit en alerte qualité}\n  other {Attention, vous avez # produits en alerte qualité}\n}\n",
      "product_count_pluralization": "{value, plural,\n  =0 {Aucun produit en vente}\n  =1 {# produit en vente}\n  other {# produits en vente}\n}\n",
      "title": "Qualité",
      "worst_comment": "Le moins bon avis client",
      "worst_product": "Votre produit le moins apprécié"
    },
    "quality_modal": {
      "no_entry": "Aucun produit en vente dans ce magasin",
      "title_pluralization": "{count, plural,\n  =0 {Produit de {shop}}\n  =1 {Produit de {shop}}\n  other {Produits de {shop}}\n}\n"
    },
    "quality_product": {
      "28_last_days": "Sur les 28 derniers jours",
      "7_last_days": "Sur les 7 derniers jours",
      "comment": {
        "history": "Historique des commentaires sur les 28 derniers jours"
      },
      "comments": {
        "no_entry": "Aucun commentaire"
      },
      "filters": {
        "all": "Toutes les notes",
        "best": "Les meilleures notes",
        "worst": "Les pires notes"
      },
      "no_entry": "Aucune note"
    }
  };
  _exports.default = _default;
});