define("supplier-portal/controllers/index/accounting/archives/index", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortProperties: ['month:desc'],
    sortedPayments: Ember.computed.sort('model', 'sortProperties'),
    groupedPayments: (0, _emberGroupBy.default)('sortedPayments', 'year')
  });

  _exports.default = _default;
});