define("supplier-portal/models/comment-archive", ["exports", "supplier-portal/models/comment"], function (_exports, _comment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _comment.default.extend({});

  _exports.default = _default;
});