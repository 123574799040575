define("supplier-portal/templates/index/debitnotes/archives/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OsAhw4Ij",
    "block": "{\"symbols\":[\"group\",\"debitnote\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"debitnotes\"],[8],[0,\"\\n\\n  \"],[7,\"ol\",true],[10,\"class\",\"breadcrumb\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"index.debitnotes\"]],{\"statements\":[[1,[28,\"t\",[\"layout.debitnotes\"],null],false]],\"parameters\":[]},null],[9],[0,\"\\n    \"],[7,\"li\",true],[10,\"class\",\"active\"],[8],[1,[28,\"t\",[\"debitnotes.archives.title\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"groupedDebitnotes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h2\",true],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[10,\"class\",\"col-xs-6 col-sm-3 col-lg-2 text-center archive_tile\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"archive\",\"index.debitnotes.archives.month\",[28,\"moment-format\",[[23,2,[\"month\"]],\"YYYY-MM-DD\"],null]]],{\"statements\":[[0,\"            \"],[1,[28,\"o-archive-tile\",null,[[\"date\",\"content\",\"tile\"],[[23,2,[\"formattedMonth\"]],[28,\"t\",[\"debitnotes.archives.debitnote_pluralization\"],[[\"value\"],[[23,2,[\"count\"]]]]],\"debitnote.archives\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/index/debitnotes/archives/index.hbs"
    }
  });

  _exports.default = _default;
});