define("supplier-portal/mirage/factories/payment", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    date(i) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      return date.toISOString().substring(0, 10);
    },

    id() {
      return this.date;
    },

    count() {
      return _faker.default.random.number({
        max: 50
      });
    },

    amount() {
      return _faker.default.random.number({
        min: 3000,
        max: 9999
      }) * 100;
    },

    operations() {
      const len = _faker.default.random.number({
        max: 20
      });

      const data = [];

      for (let i = 0; i < len; ++i) {
        data.push({
          debit: _faker.default.random.number({
            max: 100
          }) * 100,
          credit: _faker.default.random.number({
            max: 100
          }) * 100,
          label: _faker.default.finance.transactionType(),
          shop: _faker.default.random.arrayElement(["001", "003", "004", "005"]),
          type: _faker.default.finance.transactionType(),
          tag: _faker.default.random.arrayElement(["invoice", "payment", "debitnote"]),
          reference: _faker.default.random.number(),
          invoice: Math.random().toString(10).slice(2).substring(0, 10)
        });
      }

      return data;
    },

    afterCreate(obj, server) {
      obj.operations.forEach(op => {
        if (op.tag === "invoice") {
          server.create("invoice", {
            reference: op.invoice,
            shop: op.shop
          });
        }
      });
    }

  });

  _exports.default = _default;
});