define("supplier-portal/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-data"], function (_exports, _applicationRouteMixin, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service("session"),

    sessionInvalidated() {
      document.cookie = "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },

    /**
     * Setting locale
     */
    beforeModel() {
      return this.intl.setLocale("fr-fr");
    },

    actions: {
      /**
       * Handle errors globally
       */
      error(error) {
        // Check if token exists
        if (error.isAdapterError) {
          if (error.errors[0] !== "undefined" && error.errors[0].status === "401") {
            if (this.get("session.isAuthenticated")) {
              const regex = /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/;
              const token = document.cookie.replace(regex, "$1");

              if (!token) {
                this.session.invalidate().then(() => this.transitionTo("login"));
              }
            }
          }
        }

        if (error instanceof _emberData.default.InvalidError && error.code === "upgrade-required") {
          const msg = this.intl.t("error.upgrade_needed");
          const link = this.intl.t("error.upgrade_link");
          const html = "<span>".concat(msg, "<br /><a href=\"/\">").concat(link, "</a></span>");
          this.notify.error({
            html: html
          }, {
            closeAfter: null
          });
        } else {
          /* eslint-disable no-console */
          console.error(error);
          /* eslint-enable no-console */

          this.intermediateTransitionTo("index.error");
        }
      }

    }
  });

  _exports.default = _default;
});