define("supplier-portal/mirage/factories/purchase-summary", ["exports", "ember-cli-mirage", "faker", "moment"], function (_exports, _emberCliMirage, _faker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    amount() {
      return _faker.default.random.number({
        max: 9999
      });
    },

    date() {
      return (0, _moment.default)(_faker.default.date.past(5, new Date())).format("YYYY-MM-DD");
    },

    reference(i) {
      return this.date.replace(/-/g, "") + " " + ("00" + (i + 1)).slice(-3);
    },

    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    supplier_reference() {
      return Math.random().toString(10).slice(2);
    },

    afterCreate(obj, server) {
      server.create("purchase", {
        shop: obj.shop,
        reference: obj.reference
      });
    }

  });

  _exports.default = _default;
});