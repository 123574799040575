define("supplier-portal/serializers/purchase", ["exports", "supplier-portal/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: "id",
    attrs: {
      lines: {
        embedded: 'always'
      }
    },

    /**
     * Only purchase lines are sent to the backend
     */
    serialize() {
      const json = this._super(...arguments);

      json.lines = json.lines.map(line => line.lines[0]);
      return json;
    },

    /**
     * Patch response to build the id key and sort lines product alphabetical
     */
    normalizeFindRecordResponse(store, primaryModelClass, payload) {
      payload.purchase.id = "".concat(payload.purchase.shop, "/").concat(payload.purchase.reference);
      const lines = payload.purchase.lines;
      lines.sort((a, b) => a.product.supplier_name.localeCompare(b.product.supplier_name));
      payload.purchase.lines = lines;
      return this._super(...arguments);
    },

    /**
     * Patch response to build the id key
     */
    normalizeFindAllResponse(store, primaryModelClass, payload) {
      const data = payload.purchases.map(purchase => {
        purchase.id = "".concat(purchase.shop, "/").concat(purchase.reference);
        return purchase;
      });
      payload = data;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});