define("supplier-portal/serializers/purchase-summary-detail", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',

    modelNameFromPayloadKey() {
      return 'purchase-summary-detail';
    },

    /**
     * Patch response to build the id key and sort lines product alphabetical
     */
    normalizeFindRecordResponse(store, primaryModelClass, payload) {
      const id = "".concat(payload.purchase.shop, "/").concat(payload.purchase.reference);
      payload.purchase.id = id;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});