define("supplier-portal/mirage/serializers/notations-product", ["exports", "supplier-portal/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForModel() {
      if (this.request.queryParams.type === 'alerts') {
        return 'alerts';
      }

      return 'summary';
    }

  });

  _exports.default = _default;
});