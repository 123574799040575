define("supplier-portal/components/o-validation-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elementId: "modal",
    _cancelButton: null,
    _okButton: null,

    didInsertElement() {
      this._super(...arguments);

      const that = this;
      this._cancelButton = (0, _jquery.default)(this.element.querySelectorAll('div')).find('.btn-default');
      this._okButton = (0, _jquery.default)(this.element.querySelectorAll('div')).find('.btn-primary');
      (0, _jquery.default)(this.element.querySelectorAll('div')).on('hidden.bs.modal', () => that._okButton.off("click"));
    }

  });

  _exports.default = _default;
});