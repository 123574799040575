define("supplier-portal/templates/index/accounting/archives/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8cISr08i",
    "block": "{\"symbols\":[\"group\",\"payment\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"payments_summary\"],[8],[0,\"\\n  \"],[7,\"ol\",true],[10,\"class\",\"breadcrumb\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"index.accounting\"]],{\"statements\":[[1,[28,\"t\",[\"layout.accounting\"],null],false]],\"parameters\":[]},null],[9],[0,\"\\n    \"],[7,\"li\",true],[10,\"class\",\"active\"],[8],[1,[28,\"t\",[\"accounting.archives.title\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"groupedPayments\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h2\",true],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[10,\"class\",\"col-xs-6 col-sm-3 col-lg-2 text-center archive_tile\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"archive\",\"index.accounting.archives.month\",[28,\"moment-format\",[[23,2,[\"month\"]],\"YYYY-MM-DD\"],null]]],{\"statements\":[[0,\"            \"],[1,[28,\"o-archive-tile\",null,[[\"date\",\"content\",\"total\"],[[28,\"format-date\",[[23,2,[\"month\"]]],[[\"year\",\"month\"],[\"numeric\",\"long\"]]],[28,\"t\",[\"accounting.archives.regulation_pluralization\"],[[\"value\"],[[23,2,[\"count\"]]]]],[28,\"format-number\",[[23,2,[\"total\"]]],[[\"format\"],[\"amount\"]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"no_entry\"],[8],[1,[28,\"t\",[\"accounting.archives.no_entry\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/index/accounting/archives/index.hbs"
    }
  });

  _exports.default = _default;
});