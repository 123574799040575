define("supplier-portal/mirage/factories/notations-shop", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    evolution() {
      return _faker.default.finance.amount(-3, 3);
    },

    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    week_average() {
      return _faker.default.finance.amount(0, 3, 1);
    },

    week_comments() {
      return _faker.default.random.number(100);
    },

    week_notations() {
      return _faker.default.random.number(3000);
    },

    products() {
      const len = _faker.default.random.number({
        max: 20
      });

      const data = [];

      for (let i = 0; i < len; ++i) {
        data.push({
          comment_count: _faker.default.random.number(100),
          product_name: _faker.default.commerce.product(),
          evolution: _faker.default.finance.amount(-3, 3),
          shop: _faker.default.random.arrayElement(["001", "003", "004", "005"]),
          reference: ("00000" + _faker.default.random.number()).slice(-6),
          week_average: _faker.default.finance.amount(0, 3, 1),
          week_comments: _faker.default.random.number(100),
          week_notations: _faker.default.random.number(3000)
        });
      }

      return data;
    }

  });

  _exports.default = _default;
});