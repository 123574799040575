define("supplier-portal/serializers/notations-summary", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'week_comments',

    modelNameFromPayloadKey() {
      return 'notations-summary';
    }

  });

  _exports.default = _default;
});