define("supplier-portal/controllers/forgot-password", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    account: null,
    email: null,
    status: null,
    queryParams: ["reason"],
    reason: null,
    recaptchaId: null,
    emptyAccount: Ember.computed.empty("account"),

    recover(url) {
      const recaptchaToken = window.grecaptcha.getResponse(this.recaptchaId);
      return new Ember.RSVP.Promise(async (resolve, reject) => {
        try {
          const body = JSON.stringify({
            recaptcha: recaptchaToken
          });

          if (window.fetch) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            const response = await fetch(url, {
              method: "POST",
              headers: headers,
              body: body
            });

            if (response.ok) {
              resolve(response.json());
            } else {
              reject(response);
            }
          } else {
            const headers = {
              "Content-Type": "application/json"
            };

            _jquery.default.ajax(url, {
              method: "POST",
              headers: headers,
              data: body
            }).then(data => {
              resolve(data);
            }, xhr => {
              reject(xhr);
            });
          }
        } catch (e) {
          reject();
        }
      });
    },

    actions: {
      async recoverPassword() {
        const account = this.account;
        const url = "/api/v2/password-lost?account=".concat(account);

        try {
          const response = await this.recover(url);
          this.set("email", response.email);
        } catch (e) {
          this.set("status", e.status);
        }
      },

      setRecaptchaId(id) {
        this.set("recaptchaId", id);
      }

    }
  });

  _exports.default = _default;
});