define("supplier-portal/components/o-comment", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    isReadOnly: Ember.computed.equal('session.data.authenticated.readonly', '1'),
    emptyAnswer: Ember.computed.empty('answer'),
    elemId: null,

    didInsertElement() {
      this._super(...arguments);

      this.set("itemId", "#item_".concat(this.get("model.shop.id"), "-").concat(this.get("model.reference")));
      this.set("elemId", "#answer_".concat(this.get("model.shop.id"), "-").concat(this.get("model.reference")));
    },

    actions: {
      autofocus() {
        const id = this.element.querySelector(".actions button").getAttribute("data-target");
        const textarea = "".concat(id, " textarea");
        (0, _jquery.default)('.panel-collapse').on('shown.bs.collapse', () => {
          (0, _jquery.default)(textarea).focus();
        });
      },

      supplierAnswered(comment) {
        const answer = this.answer;
        const deferred = new Ember.RSVP.defer();
        (0, _jquery.default)(this.elemId).find(".btn-success").html('<i class="fa fa-spinner fa-spin fa-fw"></i>');
        deferred.promise.then(() => {
          (0, _jquery.default)(this.itemId).collapse("hide");
          (0, _jquery.default)(".panel-collapse").on("hidden.bs.collapse", function () {
            comment.set("supplier_read", true);
          });
        }, () => (0, _jquery.default)("".concat(this.elemId, " .error")).show());
        this.sendAction("setAnswer", comment, answer, deferred);
      },

      supplierRead(comment) {
        const deferred = new Ember.RSVP.defer();
        (0, _jquery.default)(this.elemId).parent().find(".actions .btn-default").html('<i class="fa fa-spinner fa-spin fa-fw"></i>');
        deferred.promise.then(() => {
          (0, _jquery.default)(this.itemId).collapse("hide");
          (0, _jquery.default)(".panel-collapse").on("hidden.bs.collapse", function () {
            comment.set("supplier_read", true);
          });
        }, () => (0, _jquery.default)("".concat(this.elemId, " .error")).show());
        this.sendAction("setRead", comment, deferred);
      }

    }
  });

  _exports.default = _default;
});