define("supplier-portal/templates/index/accounting/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3J4yzrkf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ol\",true],[10,\"class\",\"breadcrumb\"],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"index.accounting\"]],{\"statements\":[[1,[28,\"t\",[\"layout.accounting\"],null],false]],\"parameters\":[]},null],[9],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"active\"],[8],[1,[28,\"t\",[\"accounting.title\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 col-md-6 text-center\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn_archive custom_button\",\"index.accounting.archives\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"glyphicon glyphicon-folder-open\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"accounting.archives.title\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 col-md-6 text-center\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"/api/v2/archives/accounting/ledger.csv\"],[10,\"target\",\"_blank\"],[10,\"class\",\"btn btn_download custom_button\"],[10,\"rel\",\"noopener\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-download\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"accounting.tile.download_ledger\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"o-timeline\",null,[[\"model\",\"datePath\",\"loadItem\",\"idPath\",\"focusLoadedItem\"],[[24,[\"model\"]],\"date\",[28,\"action\",[[23,0,[]],\"loadPayment\"],null],\"formattedDate\",[28,\"action\",[[23,0,[]],\"focusLoadedPayment\"],null]]]],false],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"no_entry\"],[8],[1,[28,\"t\",[\"accounting.payments.no_entry\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/index/accounting/index.hbs"
    }
  });

  _exports.default = _default;
});