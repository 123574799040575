define("supplier-portal/templates/components/o-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e18Jjvnq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"tileColumn\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"tile \",[22,\"tileClass\"]]]],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"icon\"]]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/components/o-tile.hbs"
    }
  });

  _exports.default = _default;
});