define("supplier-portal/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    untraitedComments: Ember.computed.filter('model.comments.@each.{supplier_read,supplier_answer}', function (obj) {
      return !obj.supplier_read;
    }),
    pendingPurchases: Ember.computed.filter('model.purchases.@each.state', function (obj, _index, _array) {
      return obj.state === "confirmed";
    })
  });

  _exports.default = _default;
});