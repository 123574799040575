define("supplier-portal/controllers/index/purchases/index", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selection: null,
    sortProperties: ['delivery_date'],
    sortedModel: Ember.computed.sort("model", "sortProperties"),
    actions: {
      focusLoadedPurchase() {
        const timelineEntries = (0, _jquery.default)('.events-wrapper ol li');
        const selection = this.selection;
        let target;

        if (!selection) {
          const len = this.get("model.length");
          let def = 0;

          for (let i = 0; i < len; ++i) {
            if (!this.sortedModel.objectAt(i).get("isProposed")) {
              def = i;
              break;
            }
          }

          timelineEntries.find("a").removeClass("selected");
          target = timelineEntries.eq(def).children("a");
          this.replaceRoute("index.purchases.index.pending", this.sortedModel.objectAt(def).get("id"));
        } else {
          target = timelineEntries.find("[data-id=\"".concat(selection, "\"]"));
        }

        target.addClass("selected");
      },

      /**
       * Load puchase
       */
      loadPurchase(id) {
        this.replaceRoute("index.purchases.index.pending", id);
      }

    },

    /**
     * Get purchases planned today
     */
    todayPurchases: Ember.computed.filter('model.[]', function (purchase) {
      return purchase.get('delivery_date').toDateString() === new Date().toDateString() && purchase.get('state') !== 'cancel';
    }),

    /**
     * Get incoming purchases
     */
    nextPurchases: Ember.computed.filter('model.[]', function (purchase) {
      return purchase.get('delivery_date').toDateString() !== new Date().toDateString() && purchase.get('delivery_date') > new Date() && purchase.get('state') !== 'cancel';
    }),

    /**
     * Get unvalidated purchases
     */
    pendingPurchases: Ember.computed.filterBy('model', 'state', 'confirmed')
  });

  _exports.default = _default;
});