define("supplier-portal/mirage/factories/comment", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    reference() {
      return Math.random().toString(10).slice(2).substring(0, 10);
    },

    comment() {
      return _faker.default.lorem.sentences();
    },

    notation_date() {
      return _faker.default.date.past(0, new Date()).toISOString();
    },

    purchase_date() {
      return _faker.default.date.past(0, new Date()).toISOString();
    },

    product_image() {
      return "";
    },

    customer() {
      return _faker.default.name.findName();
    },

    product() {
      return _faker.default.commerce.product();
    },

    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    shop_answer() {
      return _faker.default.lorem.sentences();
    },

    supplier_answer() {
      return _faker.default.lorem.sentences();
    },

    value() {
      return _faker.default.random.arrayElement([0, 1, 2, 3]);
    }

  });

  _exports.default = _default;
});