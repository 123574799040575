define("supplier-portal/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/comment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/debitnote-detail.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/debitnote-detail.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/debitnote-summary-detail.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/debitnote-summary-detail.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/debitnote-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/debitnote-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/debitnote.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/debitnote.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/debitnotes-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/debitnotes-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/invoice.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/invoice.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/notations-product.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/notations-product.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/notations-shop.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/notations-shop.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/notations-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/notations-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/payment-detail.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/payment-detail.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/payment-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/payment-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/payment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/payment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/payments-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/payments-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/purchase-line.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/purchase-line.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/purchase-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/purchase-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/purchase.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/purchase.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/purchases-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/purchases-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/comment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/debitnote-summary-detail.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/debitnote-summary-detail.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/debitnote-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/debitnote-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/debitnote.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/debitnote.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/debitnotes-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/debitnotes-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/invoice.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/invoice.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/notations-product.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/notations-product.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/notations-shop.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/notations-shop.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/notations-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/notations-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/payment-detail.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/payment-detail.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/payment-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/payment-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/payment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/payment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/payments-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/payments-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/purchase-line.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/purchase-line.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/purchase-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/purchase-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/purchase.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/purchase.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/purchases-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/purchases-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/comment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/debitnote-summary-detail.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/debitnote-summary-detail.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/debitnote-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/debitnote-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/notations-product.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/notations-product.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/notations-shop.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/notations-shop.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/notations-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/notations-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/payment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/payment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/purchase-summary.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/purchase-summary.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/purchase.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/purchase.js should pass ESLint\n\n');
  });
});