define("supplier-portal/authenticators/token", ["exports", "supplier-portal/authenticators/basic", "jquery"], function (_exports, _basic, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    /**
     * Change password and authenticate
     */
    authenticate(password, token) {
      return new Ember.RSVP.Promise(async (resolve, reject) => {
        try {
          const body = JSON.stringify({
            password: password
          });

          if (window.fetch) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            const response = await fetch("/api/v2/password-reset?token=".concat(token), {
              method: "POST",
              headers: headers,
              body: body
            });

            if (response.ok) {
              resolve({
                cacheid: Math.random().toString(36).slice(2)
              });
            } else {
              reject(response.status);
            }
          } else {
            const headers = {
              "Content-Type": "application/json"
            };

            _jquery.default.ajax("/api/v2/password-reset?token=".concat(token), {
              method: "POST",
              headers: headers,
              data: body
            }).then(() => {
              resolve({
                cacheid: Math.random().toString(36).slice(2)
              });
            }, xhr => {
              reject(xhr.status);
            });
          }
        } catch (e) {
          reject();
        }
      });
    }

  });

  _exports.default = _default;
});