define("supplier-portal/serializers/comment", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
    * Patch response to build the id key
    */
    normalizeFindAllResponse(store, primaryModelClass, payload) {
      const data = payload.comments.map(comment => {
        comment.id = "".concat(comment.shop, "/").concat(comment.reference);
        return comment;
      });
      payload = data;
      return this._super(...arguments);
    },

    normalizeQueryRecordResponse(store, primaryModelClass, payload) {
      if (payload.comment) {
        payload.comment.id = "".concat(payload.comment.shop, "/").concat(payload.comment.reference);
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});