define("supplier-portal/adapters/notations-product", ["exports", "supplier-portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQueryRecord(query) {
      if (query.type === 'best') {
        return "/".concat(this.namespace, "/notations/best");
      } else if (query.type === 'worst') {
        return "/".concat(this.namespace, "/notations/worst");
      }
    },

    urlForQuery(query) {
      if (query.type === 'alerts') {
        return "/".concat(this.namespace, "/notations/alert");
      }
    }

  });

  _exports.default = _default;
});