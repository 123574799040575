define("supplier-portal/adapters/comment-archive", ["exports", "supplier-portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery(query) {
      if (query.type === 'archive') {
        return "/".concat(this.namespace, "/archives/comments/per-shop/").concat(query.reference);
      }
    }

  });

  _exports.default = _default;
});