define("supplier-portal/models/purchase", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    reference: _emberData.default.attr('string'),
    delivery_date: _emberData.default.attr('o-date'),
    purchase_amount: _emberData.default.attr('number'),
    purchase_date: _emberData.default.attr('o-date'),
    state: _emberData.default.attr('string'),
    validator: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    shop_reference: _emberData.default.attr('string'),
    shop: _emberData.default.belongsTo('shop', {
      inverse: null
    }),
    lines: _emberData.default.hasMany('purchase-line'),
    formattedAmount: Ember.computed('purchase_amount', function () {
      return this.intl.formatNumber(this.purchase_amount, {
        format: 'amount'
      });
    }),
    timelineTitle: Ember.computed('delivery_date', function () {
      return this.intl.formatDate(this.delivery_date, {
        format: "small"
      });
    }),
    timelineSubtitle: Ember.computed.alias("shop.name"),
    isProposed: Ember.computed.equal("state", "approved")
  });

  _exports.default = _default;
});