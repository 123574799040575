define("supplier-portal/mirage/factories/payment-summary", ["exports", "ember-cli-mirage", "faker", "moment"], function (_exports, _emberCliMirage, _faker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    reference() {
      return _faker.default.random.uuid().substring(0, 6).toUpperCase() + "/0";
    },

    label() {
      return _faker.default.random.words();
    },

    date() {
      return (0, _moment.default)(_faker.default.date.past(5, new Date())).format("YYYY-MM-DD");
    },

    amount() {
      return _faker.default.random.number({
        max: 999999,
        min: 10000
      });
    },

    afterCreate(obj, server) {
      server.create("payment-detail", {
        shop: obj.shop,
        reference: obj.reference.split("/")[0]
      });
    }

  });

  _exports.default = _default;
});