define("supplier-portal/components/o-editable-cell", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    // work on td tag not div
    tagName: 'td',

    didRender() {
      this._super(...arguments);

      if (this.value !== this.proposedValue) {
        (0, _jquery.default)(this.element).addClass('edited_cell');
      } else {
        (0, _jquery.default)(this.element).removeClass('edited_cell');
      }
    },

    didInsertElement() {
      this._super(...arguments);

      const that = this; // manage cell

      (0, _jquery.default)(this.element.querySelector('a')).editable({
        highlight: 0,
        display: () => that.formattedValue,
        value: () => that.proposedValue,
        title: function () {
          let tr;

          if (that.cell === "price") {
            tr = "purchases.table.price_change";
          } else {
            tr = "purchases.table.quantity_change";
          }

          return that.get("intl").t(tr, {
            value: that.oldFormattedValue
          });
        },
        url: function (params) {
          return that._update(params.value);
        },
        validate: function (value) {
          if (_jquery.default.trim(value) === '') {
            return that.get('intl').t('purchases.table.field_required');
          }

          if (isNaN(that.filterFloat(value))) {
            if (that.cell === "quantity") {
              return that.get('intl').t('purchases.table.quantity_error');
            }

            return that.get('intl').t('purchases.table.price_error');
          }
        }
      }).on("shown", function (e, editable) {
        setTimeout(function () {
          editable.input.$input.select();
        }, 0);
      });
    },

    /**
     * Check if entered value is valid
     */
    filterFloat(value) {
      if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
        return Number(value);
      }

      return NaN;
    },

    /**
     * Send updated value to parent
     */
    _update(value) {
      const def = new Ember.RSVP.defer();
      this.sendAction("update", this.reference, parseFloat(value), def);
      return def.promise;
    }

  });

  _exports.default = _default;
});