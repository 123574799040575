define("supplier-portal/routes/index", ["exports", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    beforeModel(transition) {
      if (this.get("session.isAuthenticated")) {
        if (transition.targetName === "index.index") {
          this.replaceWith("index.dashboard");
        } else {
          this.replaceWith(transition.targetName);
        }
      } else {
        this._super(...arguments);
      }
    },

    model() {
      return Ember.RSVP.hash({
        purchases: this.store.findAll("purchase"),
        shops: this.store.findAll("shop"),
        account: this.store.queryRecord("account", {}),
        comments: this.store.findAll("comment")
      });
    },

    triggerAuthentication() {
      if (this.get("session.attemptedTransition").targetName !== "index.index") {
        this.transitionTo(this.authenticationRoute, {
          queryParams: {
            reason: "forbidden"
          }
        });
      } else {
        this._super(...arguments);
      }
    },

    actions: {
      willTransition() {
        this._super(...arguments);

        (0, _jquery.default)("#modal .close").click();
      },

      logout() {
        this.session.invalidate().then(() => this.transitionTo("login"));
      }

    }
  });

  _exports.default = _default;
});