define("supplier-portal/serializers/purchases-summary", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'month',
    modelNameFromPayloadKey: function () {
      return 'purchases-summary';
    }
  });

  _exports.default = _default;
});