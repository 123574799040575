define("supplier-portal/controllers/index/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notDeliveredPurchases: Ember.computed.filter('model.purchases.@each.state', function (obj, _index, _array) {
      return obj.state === "confirmed";
    }),
    closedShops: Ember.computed.filter('model.shops.@each.reference', function (obj, _index, _array) {
      return ['006', '007', '008'].includes(obj.reference);
    })
  });

  _exports.default = _default;
});