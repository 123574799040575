define("supplier-portal/controllers/index/mobile", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    isReadOnly: Ember.computed.equal('session.data.authenticated.readonly', '1'),

    displayKey(key) {
      this._super(...arguments);

      (0, _jquery.default)(".expired").hide();
      (0, _jquery.default)(".app_code").html("");
      Array.from(key).forEach(k => {
        (0, _jquery.default)(".app_code").append("<span>" + k + "</span>");
      });
      (0, _jquery.default)(".available").show();
      this.checkKeyExpiration();
    },

    checkKeyExpiration() {
      const _this = this; // stop request when a new demand is made


      if (_this.loop) {
        Ember.run.cancel(_this.loop);
      }

      _jquery.default.ajax({
        url: "/api/v2/otp",
        method: "GET"
      }).then(function (data) {
        _this.stop = false;

        if (!data.otp_token.active) {
          if (data.otp_token.used_on !== null) {
            (0, _jquery.default)("#collapse_3").collapse("show");
          }

          (0, _jquery.default)(".available").hide();
          (0, _jquery.default)(".expired").show();
          Ember.run.cancel(_this.loop);
        } else {
          (0, _jquery.default)(".expired").hide();
          (0, _jquery.default)(".time").text(data.otp_token.remaining);
        }
      }).fail(function () {
        if (_this.loop) {
          Ember.run.cancel(_this.loop);
        }
      });

      _this.loop = Ember.run.later(this, () => this.checkKeyExpiration(), 1 * 1000);
    },

    actions: {
      generateOtp() {
        _jquery.default.ajax({
          url: "/api/v2/otp",
          method: "POST"
        }).then(data => this.displayKey(data.otp_token.key));
      },

      removeOtp() {
        _jquery.default.ajax({
          url: "/api/v2/otp",
          method: "DELETE"
        }).then(() => (0, _jquery.default)("#collapse_1").collapse("show"));
      }

    }
  });

  _exports.default = _default;
});