define("supplier-portal/routes/index/purchases/index/pending", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      const purchase = this.store.peekRecord("purchase", params.id);

      if (!purchase) {
        return null;
      }

      return this.store.findRecord("purchase", params.id);
    },

    afterModel(_model, _transition) {
      const params = this.paramsFor(this.routeName);
      let selection = params.id;
      const purchase = this.store.peekRecord("purchase", params.id);

      if (!purchase) {
        selection = null;
      }

      this.controllerFor("index.purchases.index").set("selection", selection);
    }

  });

  _exports.default = _default;
});