define("supplier-portal/adapters/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    namespace: "api/v2",
    version: null,
    shopNotifications: null,

    init() {
      this._super(...arguments);

      this.set("headers", {
        "Accept": "application/json",
        "Content-Type": "application/json"
      });
      this.set("shopNotifications", {});
    },

    /**
     * Check the X-Version response headers :
     *
     * 1. If not defined, save it.
     * 2. If current version is different than the X-Version headers
     * return an invalid error
     */
    handleResponse(status, headers) {
      const xVersion = headers["X-Version"];

      if (!this.version) {
        this.version = xVersion;
      } else if (this.version !== xVersion) {
        const error = new _emberData.default.InvalidError();
        error.code = "upgrade-required";
        error.message = "Deprecated version, update required";
        return error;
      }

      if (headers["x-errors"]) {
        const shopsUnavailable = headers["x-errors"].split(",");
        let shop;
        shopsUnavailable.forEach(ref => {
          shop = this.store.peekRecord("shop", ref);

          if (!(ref in this.shopNotifications) || this.shopNotifications[ref].closed) {
            this.shopNotifications[ref] = this.notify.error(this.intl.t("error.shop_unavailable", {
              shop: shop.get("name")
            }), {
              closeAfter: null
            });
          }
        });
      }

      return this._super(...arguments);
    },

    /**
     * Add the cache-id param to each request URL
     */
    buildURL() {
      let url = this._super(...arguments);

      const cacheId = this.get("session.data.authenticated.cacheid");

      if (url.indexOf("?") !== -1) {
        url = "".concat(url, "&cache=").concat(cacheId);
      } else {
        url = "".concat(url, "?cache=").concat(cacheId);
      }

      return url;
    }

  });

  _exports.default = _default;
});