define("supplier-portal/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    date: {
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      },
      small: {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      onlydate: {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
    },
    number: {
      price: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
      },
      amount: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  };
  _exports.default = _default;
});