define("supplier-portal/models/debitnotes-summary", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    count: _emberData.default.attr('number'),
    total: _emberData.default.attr('number'),
    month: _emberData.default.attr('o-date'),
    formattedMonth: Ember.computed('month', function () {
      return this.intl.formatDate(this.month, {
        year: 'numeric',
        month: 'long'
      });
    }),
    year: Ember.computed('month', function () {
      return this.month.getFullYear();
    })
  });

  _exports.default = _default;
});