define("supplier-portal/serializers/notations-shop", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'shop',

    modelNameFromPayloadKey() {
      return 'notations-shop';
    },

    /**
     * Patch response to build the id key
     */
    normalizeFindRecordResponse(store, primaryModelClass, payload) {
      if (payload.summary.products) {
        const data = payload.summary.products.map(product => {
          product.id = "".concat(product.shop, "/").concat(product.reference);
          return product;
        });
        payload.summary.products = data;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});