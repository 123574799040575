define("supplier-portal/templates/components/o-linked-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lvll6aTy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"link-to\",null,[[\"route\"],[[24,[\"link\"]]]],{\"statements\":[[4,\"o-tile\",null,[[\"tileColumn\",\"tileClass\",\"icon\"],[[24,[\"tileColumn\"]],[24,[\"tileClass\"]],[24,[\"icon\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/components/o-linked-tile.hbs"
    }
  });

  _exports.default = _default;
});