define("supplier-portal/mirage/serializers/application", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }

  });

  _exports.default = _default;
});