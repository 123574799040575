define("supplier-portal/serializers/purchase-summary", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function () {
      return 'purchase-summary';
    },

    normalizeQueryResponse(store, primaryModelClass, payload) {
      const data = payload.purchases.map(purchase => {
        purchase.id = "".concat(purchase.shop, "/").concat(purchase.reference);
        return purchase;
      });
      payload = data;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});