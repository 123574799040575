define("supplier-portal/serializers/notations-product", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',

    modelNameFromPayloadKey() {
      return 'notations-product';
    },

    /**
     * Patch response to build the id key
     */
    normalizeQueryRecordResponse(store, primaryModelClass, payload) {
      if (payload.summary) {
        payload.summary.id = "".concat(payload.summary.shop, "/").concat(payload.summary.reference);
      }

      return this._super(...arguments);
    },

    /**
     * Patch response to build the id key
     */
    normalizeQueryResponse(store, primaryModelClass, payload) {
      const data = payload.alerts.map(alert => {
        alert.id = "".concat(alert.shop, "/").concat(alert.reference);
        return alert;
      });
      payload = data;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});