define("supplier-portal/controllers/index/accounting/archives/month/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['ref'],
    ref: null,
    filteredPayments: Ember.computed('ref', 'model', function () {
      const shop = this.ref;
      const payments = this.get("model.payments");

      if (shop) {
        return payments.filterBy("shop.reference", shop);
      }

      return payments;
    }),
    actions: {
      filterPayments(shop) {
        const route = "index.accounting.archives.month.index";
        const queryParams = {
          ref: null
        };

        if (shop) {
          queryParams.ref = shop;
        }

        this.replaceRoute(route, {
          queryParams: queryParams
        });
      }

    }
  });

  _exports.default = _default;
});