define("supplier-portal/helpers/formatted-quantity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute([value, unit, weight_unit]) {
      const digits = weight_unit ? 3 : 0;
      return this.intl.formatNumber(value, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + '\xa0' + unit;
    }

  });

  _exports.default = _default;
});