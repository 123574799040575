define("supplier-portal/serializers/debitnote", ["exports", "supplier-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: "id",

    /**
     * Patch response to build to id key
     */
    normalizeFindRecordResponse(store, primaryModelClass, payload) {
      const id = "".concat(payload.debitnote.shop, "/").concat(payload.debitnote.reference);
      payload.debitnote.id = id;
      return this._super(...arguments);
    },

    /**
     * Patch response to build to id key
     */
    normalizeFindAllResponse(store, primaryModelClass, payload) {
      const data = payload.debitnotes.map(debitnote => {
        debitnote.id = "".concat(debitnote.shop, "/").concat(debitnote.reference);
        return debitnote;
      });
      payload = data;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});