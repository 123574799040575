define("supplier-portal/adapters/payment-detail", ["exports", "supplier-portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord(id, modelName, snapshot) {
      const date = snapshot.adapterOptions.date;
      const path = this.pathForType();
      return "/".concat(this.namespace, "/").concat(path, "/").concat(date, "/").concat(id);
    },

    pathForType() {
      return "archives/payments/per-month";
    }

  });

  _exports.default = _default;
});