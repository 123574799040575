define("supplier-portal/router", ["exports", "supplier-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    didTransition() {
      this._super(...arguments);

      window.scrollTo(0, 0);
    }

  });
  Router.map(function () {
    this.route('login');
    this.route('preauth');
    this.route('forgot-password');
    this.route('password-reset');
    this.route('index', {
      path: '/'
    }, function () {
      this.route('dashboard');
      this.route('purchases', function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.route('pending', {
            path: ":id"
          });
        });
        this.route('archives', function () {
          this.route('month', {
            path: ':date'
          }, function () {});
        });
      });
      this.route('accounting', function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.route('pending', {
            path: ':date'
          });
        });
        this.route('archives', function () {
          this.route('month', {
            path: ':date'
          }, function () {
            this.route('detail', {
              path: ':reference'
            });
          });
        });
      });
      this.route('debitnotes', function () {
        this.route('index', {
          path: '/'
        });
        this.route('archives', function () {
          this.route('month', {
            path: ':date'
          }, function () {});
        });
      });
      this.route('error');
      this.route('legal_notice');
      this.route('comments', function () {});
      this.route('administration', function () {});
      this.route('quality', function () {
        this.route('product', {
          path: ':reference'
        }, function () {});
      });
      this.route('mobile');
    });
  });
  var _default = Router;
  _exports.default = _default;
});