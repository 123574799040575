define("supplier-portal/mirage/factories/debitnote-detail", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    reference() {
      return this.shop + "/NDD" + Math.random().toString(10).slice(2).substring(0, 10);
    },

    date() {
      return _faker.default.date.past(5, new Date());
    },

    amount() {
      return _faker.default.random.number({
        max: 1000
      });
    },

    lines() {
      const len = _faker.default.random.number({
        min: 2,
        max: 20
      });

      const res = [];

      for (let i = 0; i < len; ++i) {
        res.push({
          amount: _faker.default.random.number({
            max: 100
          }),
          price: _faker.default.random.number({
            max: 100
          }),
          quantity: _faker.default.random.number({
            max: 1000
          }),
          unit: "Kg",
          vat: "5.5"
        });
      }

      return res;
    }

  });

  _exports.default = _default;
});