define("supplier-portal/templates/index/purchases/archives/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WmJ0NdI4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"loader\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-spin fa-3x fa-fw\"],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"sr-only\"],[8],[1,[28,\"t\",[\"loading\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/index/purchases/archives/loading.hbs"
    }
  });

  _exports.default = _default;
});