define("supplier-portal/models/debitnote", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    reference: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    term_date: _emberData.default.attr('o-date'),
    date: _emberData.default.attr('o-date'),
    shop: _emberData.default.belongsTo('shop', {
      inverse: null
    }),
    state: _emberData.default.attr('string'),
    _type: Ember.computed('type', function () {
      if (this.type === 'invoice' || this.type === 'delivery') {
        return 'invoice';
      }

      return 'supplier_breakage';
    })
  });

  _exports.default = _default;
});