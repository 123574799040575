define("supplier-portal/controllers/index/debitnotes/archives/index", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortProperties: ['month:desc'],
    sortedDebitnotes: Ember.computed.sort('model', 'sortProperties'),
    groupedDebitnotes: (0, _emberGroupBy.default)('sortedDebitnotes', 'year')
  });

  _exports.default = _default;
});