define("supplier-portal/controllers/application", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    data: null,
    init: function () {
      this._super(...arguments);

      const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, "$1");

      if (this.get('session.isAuthenticated') && !token) {
        this.session.invalidate().then(() => this.transitionToRoute('login'));
      }
    },
    actions: {
      openModal(params) {
        let data = null;

        switch (params.type) {
          case 'debitnote':
            data = this.store.findRecord('debitnote-summary-detail', params.reference);
            break;

          case 'purchase':
            data = this.store.findRecord('purchase-summary-detail', params.reference);
            break;

          case 'administration':
            data = {};
            data.field = params.field;
            break;

          case 'invoice':
            data = this.store.findRecord('invoice', params.reference);
            break;

          case 'quality':
            data = {};
            data.shop_name = params.shop_name;
            data.products = params.products;
            break;
        }

        data.data_type = params.type;
        this.set('data', data); // display modal

        const modal = (0, _jquery.default)('#modal').children('div');
        modal.modal('show'); // autofocus first input

        modal.on('shown.bs.modal', function () {
          modal.find('input:eq(0)').focus();
        });
      }

    }
  });

  _exports.default = _default;
});