define("supplier-portal/routes/index/accounting/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    intl: Ember.inject.service(),

    model() {
      return this.store.findAll("payment").then(payments => payments.sortBy("date"));
    },

    actions: {
      /**
       * Reset controller's selection to null to always redirect
       * to the pending route
       */
      willTransition(transition) {
        // Prevent transition to index.accounting.index.index
        if (transition.targetName === "index.accounting.index.index") {
          transition.abort();
        }

        this.controller.set("selection", null);
        return true;
      }

    }
  });

  _exports.default = _default;
});