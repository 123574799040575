define("supplier-portal/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
       Make sure to define a factory for each model you want to create.
    */
    // server.createList('post', 10);
    server.createList("purchase", 5);
    server.createList("payment", 10);
    server.createList("debitnote", 8);
    server.createList("comment", 15);
    server.createList("payments-summary", 5);
    server.createList("purchases-summary", 3);
    server.createList("debitnotes-summary", 5);
    server.createList("comment", 4, {
      supplier_answer: ''
    });
    server.createList("comment", 2, {
      supplier_answer: '',
      shop_answer: ''
    });
    server.createList("notations-summary", 2);
    server.createList("notations-product", 2, {
      type: 'best'
    });
    server.createList("notations-product", 3, {
      type: 'worst'
    });
    server.createList("notations-product", 5, {
      type: 'alert'
    });
    server.create("notations-shop", {
      shop: '001'
    });
    server.create("notations-shop", {
      shop: '003'
    });
    server.create("notations-shop", {
      shop: '004'
    });
    server.create("notations-shop", {
      shop: '005'
    });
    server.create("comment", {
      type: 'best'
    });
    server.create("comment", {
      type: 'worst'
    });
  }
});