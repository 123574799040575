define("supplier-portal/controllers/index/debitnotes/index", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Use global controller
    data: Ember.computed.alias("appCont.data"),
    appCont: Ember.inject.controller('application'),
    queryParams: ['ref'],
    ref: null,
    // Init sorting debitnotes
    sortAttr: 'term_date',
    sortOrder: false,
    sortDefinition: Ember.computed('sortAttr', 'sortOrder', function () {
      const sortOrder = this.sortOrder ? 'desc' : 'asc';
      return ["".concat(this.sortAttr, ":").concat(sortOrder)];
    }),
    sortedDebitnotes: Ember.computed.sort('model.debitnotes', 'sortDefinition'),
    filteredDebitnotes: Ember.computed('ref', 'sortedDebitnotes', function () {
      const shop = this.ref;
      const debitnotes = this.sortedDebitnotes;

      if (shop) {
        return debitnotes.filterBy('shop.reference', shop);
      }

      return debitnotes;
    }),
    groupedDebitnotes: (0, _emberGroupBy.default)('filteredDebitnotes', '_type'),
    totalInvoice: Ember.computed.filter('model.debitnotes.@each._type', function (obj, _index, _array) {
      return obj._type === "invoice" && obj.state != "cancel";
    }),
    totalQuality: Ember.computed.filter('model.debitnotes.@each._type', function (obj, _index, _array) {
      return obj._type === 'supplier_breakage' && obj.state != "cancel";
    }),
    actions: {
      filterDebitnotes(shop) {
        const route = 'index.debitnotes.index';
        const queryParams = {
          ref: null
        };

        if (shop) {
          queryParams.ref = shop;
        }

        this.replaceRoute(route, {
          queryParams: queryParams
        });
      },

      sortBy(sortAttr) {
        if (sortAttr === this.sortAttr) {
          this.toggleProperty('sortOrder');
        } else {
          this.set('sortOrder', false);
          this.set('sortAttr', sortAttr);
        }

        this.sortDefinition;
      }

    }
  });

  _exports.default = _default;
});