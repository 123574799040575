define("supplier-portal/templates/components/o-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iGr/YBZk",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"timeline\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"events-wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"events\"],[8],[0,\"\\n      \"],[7,\"ol\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[8],[0,\"\\n            \"],[7,\"a\",true],[10,\"href\",\"#\"],[11,\"class\",[28,\"get\",[[23,1,[]],[24,[\"itemClass\"]]],null]],[11,\"data-id\",[28,\"get\",[[23,1,[]],[24,[\"idPath\"]]],null]],[8],[0,\"\\n              \"],[7,\"p\",true],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[\"timelineTitle\"]],false],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"subtitle\"],[8],[1,[23,1,[\"timelineSubtitle\"]],false],[9],[0,\"\\n              \"],[9],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"timeline-navigation\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"prev\"],[8],[9],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"next\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/components/o-timeline.hbs"
    }
  });

  _exports.default = _default;
});