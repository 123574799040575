define("supplier-portal/mirage/factories/notations-summary", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    evolution() {
      return _faker.default.finance.amount(-3, 3);
    },

    week_average() {
      return _faker.default.finance.amount(0, 3, 1);
    },

    week_comments() {
      return _faker.default.random.number(100);
    },

    week_notations() {
      return _faker.default.random.number(3000);
    }

  });

  _exports.default = _default;
});