define("supplier-portal/authenticators/basic", ["exports", "ember-simple-auth/authenticators/base", "jquery"], function (_exports, _base, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // XXX: For IE :/
  var _default = _base.default.extend({
    store: Ember.inject.service(),

    /**
     * Restore the session
     */
    restore(data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (Ember.isEmpty(data.cacheid)) {
          reject();
        } else {
          resolve(data);
        }
      });
    },

    /**
     * Authenticate the session with the credentials passed to the login form
     */
    authenticate(uid, password) {
      // https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
      const auth = "Basic " + btoa(uid.toLowerCase() + ":" + unescape(encodeURIComponent(password)));
      const version = "WEB 1.0";
      return new Ember.RSVP.Promise(async (resolve, reject) => {
        try {
          if (window.fetch) {
            const headers = new Headers();
            headers.append("Authorization", auth);
            headers.append("X-Application", version);
            const response = await fetch("/api/v2/login", {
              credentials: 'same-origin',
              headers: headers
            });

            if (response.ok) {
              resolve({
                cacheid: Math.random().toString(36).slice(2)
              });
            } else {
              reject(response.status);
            }
          } else {
            _jquery.default.ajax("/api/v2/login", {
              headers: {
                "authorization": auth,
                "X-Application": version
              }
            }).then(() => {
              resolve({
                cacheid: Math.random().toString(36).slice(2)
              });
            }, xhr => {
              reject(xhr.status);
            });
          }
        } catch (e) {
          reject();
        }
      });
    },

    invalidate() {
      // XXX : Workaround unloadAll bugged
      const shops = this.store.peekAll('shop');
      shops.forEach(() => []);
      this.store.unloadAll();
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});