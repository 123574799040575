define("supplier-portal/models/invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    date: _emberData.default.attr('o-date'),
    lines: _emberData.default.attr(),
    reference: _emberData.default.attr('string'),
    shop: _emberData.default.belongsTo('shop', {
      inverse: null
    }),
    shop_reference: _emberData.default.attr('string'),
    supplier_reference: _emberData.default.attr('string'),
    total_amount: _emberData.default.attr('number')
  });

  _exports.default = _default;
});