define("supplier-portal/mirage/factories/debitnote-summary", ["exports", "ember-cli-mirage", "faker", "moment"], function (_exports, _emberCliMirage, _faker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    reference() {
      return "NDD" + Math.random().toString(10).slice(2).substring(0, 10);
    },

    amount() {
      return _faker.default.random.number({
        max: 1000
      });
    },

    date() {
      return (0, _moment.default)(_faker.default.date.past(5, new Date())).format("YYYY-MM-DD");
    },

    type() {
      return _faker.default.random.arrayElement(["supplier_breakage", "delivery"]);
    },

    comment() {
      return _faker.default.random.words();
    },

    term_date() {
      return _faker.default.date.future(0, (0, _moment.default)(this.date).toDate()).toISOString();
    },

    afterCreate(obj, server) {
      server.create("debitnote-summary-detail", {
        shop: obj.shop,
        reference: obj.reference
      });
    }

  });

  _exports.default = _default;
});