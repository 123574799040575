define("supplier-portal/templates/components/o-validation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ai8dGHIF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal fade\"],[10,\"tabindex\",\"-1\"],[10,\"role\",\"dialog\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[10,\"role\",\"document\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"close\"],[10,\"data-dismiss\",\"modal\"],[10,\"aria-label\",\"Close\"],[10,\"type\",\"button\"],[8],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[9],[0,\"\\n        \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"btn btn-default\"],[10,\"data-dismiss\",\"modal\"],[10,\"type\",\"button\"],[8],[1,[28,\"t\",[\"modal.cancel\"],null],false],[9],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"button\"],[8],[1,[28,\"t\",[\"modal.valid\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/components/o-validation-modal.hbs"
    }
  });

  _exports.default = _default;
});