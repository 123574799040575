define("supplier-portal/controllers/index/quality/product/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    filter: '',
    sortingKey: ['notation_date:desc'],
    sortedComments: Ember.computed.sort('model.comments', 'sortingKey'),
    filteredComments: Ember.computed('filter', 'sortedComments', function () {
      const data = this.sortedComments;
      const filter = this.filter;

      if (Ember.isEmpty(filter)) {
        return data;
      }

      return data.filter(function (data) {
        if (filter === 'worst' && (data.get('value') === 0 || data.get('value') === 1)) {
          return true;
        } else if (filter === 'best' && (data.get('value') === 2 || data.get('value') === 3)) {
          return true;
        }

        return false;
      });
    }),
    actions: {
      filterBy(val) {
        this.set('filter', val);
      }

    }
  });

  _exports.default = _default;
});