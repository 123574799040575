define("supplier-portal/models/payment", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    date: _emberData.default.attr('o-date'),
    amount: _emberData.default.attr('number'),
    count: _emberData.default.attr('number'),
    operations: _emberData.default.attr(),
    formattedDate: Ember.computed('date', function () {
      return (0, _moment.default)(this.date).format("YYYY-MM-DD");
    }),
    timelineTitle: Ember.computed('amount', function () {
      return this.intl.formatNumber(this.amount, {
        format: "amount"
      });
    }),
    timelineSubtitle: Ember.computed('date', function () {
      return this.intl.formatDate(this.date, {
        format: "onlydate"
      });
    })
  });

  _exports.default = _default;
});