define("supplier-portal/models/notations-shop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    evolution: _emberData.default.attr('number'),
    shop: _emberData.default.belongsTo('shop', {
      inverse: null
    }),
    products: _emberData.default.attr(),
    week_average: _emberData.default.attr('number'),
    week_comments: _emberData.default.attr('number'),
    week_notations: _emberData.default.attr('number')
  });

  _exports.default = _default;
});