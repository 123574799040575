define("supplier-portal/mirage/factories/debitnote", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    reference() {
      return Math.random().toString(10).slice(2).substring(0, 10);
    },

    date() {
      return _faker.default.date.future(0, new Date()).toISOString();
    },

    term_date() {
      return _faker.default.date.future(0, new Date()).toISOString();
    },

    type() {
      return _faker.default.random.arrayElement(["delivery", "supplier_breakage"]);
    },

    validator() {
      return _faker.default.name.findName();
    },

    comment() {
      return _faker.default.lorem.sentences();
    },

    amount() {
      return _faker.default.random.number({
        max: 100000
      });
    },

    lines() {
      const len = _faker.default.random.number({
        max: 20
      });

      const res = [];

      for (let i = 0; i < len; ++i) {
        res.push({
          product: _faker.default.commerce.product(),
          amount: _faker.default.random.number({
            max: 100
          }),
          price: _faker.default.random.number({
            max: 50
          }),
          quantity: _faker.default.random.number({
            max: 50
          }),
          unit: _faker.default.random.arrayElement(["Pce", "Kg"]),
          vat: _faker.default.random.arrayElement(["5.5", "10", "20"])
        });
      }

      return res;
    }

  });

  _exports.default = _default;
});