define("supplier-portal/controllers/login", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    queryParams: ["reason"],
    reason: null,
    uid: null,
    password: null,
    error: null,
    emptyCredentials: Ember.computed("uid", "password", function () {
      return Ember.isEmpty(this.uid) || Ember.isEmpty(this.password);
    }),
    actions: {
      async authenticate() {
        const {
          uid,
          password
        } = this;

        try {
          await this.session.authenticate("authenticator:basic", uid, password);
        } catch (e) {
          this.setProperties({
            status: e,
            error: true,
            password: null
          });
          (0, _jquery.default)("#password").focus();
        }
      }

    }
  });

  _exports.default = _default;
});