define("supplier-portal/templates/index/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X7zqSoZj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[28,\"t\",[\"error.detail\"],null],false],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"index.dashboard\"]],{\"statements\":[[1,[28,\"t\",[\"error.goback\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/index/error.hbs"
    }
  });

  _exports.default = _default;
});