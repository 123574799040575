define("supplier-portal/mirage/config", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.namespace = "/api";
    document.cookie = 'token=exist';
    this.get("/login", (db, request) => {
      // uid = otera, password = otera for a valid authentification
      const validAuth = "Basic " + btoa("otera:otera");

      if (request.requestHeaders.authorization === validAuth) {
        return new _emberCliMirage.Response(200, {
          "X-Version": _faker.default.random.number({
            max: 100
          }) + ""
        }, {
          uid: "otera"
        });
      }

      return new _emberCliMirage.Response(401, {}, {
        error: "error"
      });
    });
    this.get("/login/:id", (db, request) => {
      if (request.params.id === "otera") {
        return new _emberCliMirage.Response(200, {}, {
          uid: "otera"
        });
      }

      return new _emberCliMirage.Response(401, {}, {});
    });
    this.get("/account", () => {
      return {
        account: {
          login: _faker.default.internet.userName(),
          name: _faker.default.company.companyName(),
          phone: _faker.default.phone.phoneNumber(),
          notifications: [],
          address: _faker.default.address.streetAddress(),
          town: _faker.default.address.city(),
          zip: _faker.default.address.zipCode(),
          siret: "1234567890",
          email: _faker.default.internet.email()
        }
      };
    });
    this.put("/account", () => {
      return new _emberCliMirage.Response(200, {}, {});
    });
    this.post("/password-lost", (_db, request) => {
      if (request.queryParams.account === "otera") {
        return new _emberCliMirage.Response(200, {}, {
          email: "otera@otera.local"
        });
      }

      return new _emberCliMirage.Response(404, {}, {
        message: "Account not found"
      });
    });
    this.post("/password-reset", (_db, request) => {
      const body = JSON.parse(request.requestBody);

      if (body.password === "Otera59650") {
        return new _emberCliMirage.Response(200, {}, {
          uid: "otera"
        });
      }

      return new _emberCliMirage.Response(500, {}, {});
    });
    this.get("/purchases");
    this.get("/purchases/:id", (db, request) => {
      const [shop, reference] = request.params.id.split("/");
      return db.purchases.findBy({
        shop: shop,
        reference: reference
      });
    });
    this.put("/purchases/:id", (db, request) => {
      const [shop, reference] = request.params.id.split("/");
      const purchase = db.purchases.findBy({
        shop: shop,
        reference: reference
      });
      purchase.lines.models.forEach(line => {
        line.update({
          proposed_quantity: line.purchased_quantity,
          proposed_price: line.purchased_price
        });
      });
      return new _emberCliMirage.Response(201, {}, {});
    });
    this.post("/purchases/:id", (db, request) => {
      const [shop, reference] = request.params.id.split("/");
      db.purchases.findBy({
        shop: shop,
        reference: reference
      }).update({
        state: "approved"
      });
      return new _emberCliMirage.Response(200, {}, {
        response: "Confirmed"
      });
    });
    this.get("/archives/purchases", db => {
      return db.purchasesSummaries.all();
    });
    this.get("/archives/purchases/per-month/:id", (db, request) => {
      return db.purchaseSummaries.where(obj => {
        return obj.date.indexOf(request.params.id.substring(0, 8)) !== -1;
      });
    });
    this.get("/archives/purchases/per-shop/:id", (db, request) => {
      const [shop, reference] = request.params.id.split("/");
      return db.purchases.findBy({
        shop: shop,
        reference: reference
      });
    });
    this.get("/payments");
    this.get("/payments/:id");
    this.get("/shops", () => {
      return {
        shops: [{
          reference: "003",
          name: "O'tera d'Avelin"
        }, {
          reference: "001",
          name: "O'tera du Sart"
        }, {
          reference: "004",
          name: "O'tera Saint André"
        }, {
          reference: "005",
          name: "O'tera Aulnoy"
        }]
      };
    });
    this.get("/archives/payments", db => {
      return db.paymentsSummaries.all();
    });
    this.get("/archives/payments/per-month/:id", (db, request) => {
      return db.paymentSummaries.where(obj => {
        return obj.date.indexOf(request.params.id.substring(0, 8)) !== -1;
      });
    });
    this.get("/archives/payments/per-month/:date/:id/0", (db, request) => {
      return db.paymentDetails.findBy({
        reference: request.params.id
      });
    });
    this.get("/debitnotes");
    this.get("/archives/debitnotes", db => {
      return db.debitnotesSummaries.all();
    });
    this.get("/archives/debitnotes/per-month/:id", (db, request) => {
      return db.debitnoteSummaries.where(obj => {
        return obj.date.indexOf(request.params.id.substring(0, 8)) !== -1;
      });
    });
    this.get("/archives/debitnotes/per-shop/:id", (db, request) => {
      const [shop, reference] = request.params.id.split("/");
      return db.debitnoteSummaryDetails.findBy({
        shop: shop,
        reference: reference
      });
    });
    this.get("/comments");
    this.get("/comments/best", db => {
      return db.comments.findBy({
        type: "best"
      });
    });
    this.get("/comments/worst", db => {
      return db.comments.findBy({
        type: "worst"
      });
    });
    this.get("/archives/comments/per-shop/:shop/:reference", (db, request) => {
      return db.comments.where({
        type: "archive",
        shop: request.params.shop,
        product_reference: request.params.reference
      });
    });
    this.post("/comments/:id", () => {
      return new _emberCliMirage.Response(200, {}, {
        response: "Done"
      });
    });
    this.get("/archives/invoices/per-shop/:id", (db, request) => {
      const [shop, reference] = request.params.id.split("/");
      return db.invoices.findBy({
        shop: shop,
        reference: reference
      });
    });
    this.get("/notations", db => {
      return db.notationsSummaries.first();
    });
    this.get("/notations/:shop", (db, request) => {
      return db.notationsShops.findBy({
        shop: request.params.shop
      });
    });
    this.get("/notations/best", db => {
      return db.notationsProducts.findBy({
        type: "best"
      });
    });
    this.get("/notations/worst", db => {
      return db.notationsProducts.findBy({
        type: "worst"
      });
    });
    this.get("/notations/alert", db => {
      return db.notationsProducts.all({
        type: "alert"
      });
    });
  }
});