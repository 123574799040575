define("supplier-portal/components/o-comment-readonly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isAnswered: Ember.computed.or('comment.shop_answer', 'comment.supplier_answer'),
    technicalRef: Ember.computed('comment.{shop.id,reference}', function () {
      return "#answers_".concat(this.get('comment.shop.id'), "-").concat(this.get('comment.reference'));
    })
  });

  _exports.default = _default;
});