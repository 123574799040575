define("supplier-portal/mirage/factories/notations-product", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    comment_count() {
      return _faker.default.random.number(100);
    },

    product_name() {
      return _faker.default.commerce.product();
    },

    evolution() {
      return _faker.default.finance.amount(-3, 3);
    },

    full_average() {
      return _faker.default.finance.amount(0, 3, 1);
    },

    full_comments() {
      return _faker.default.random.number(30);
    },

    full_notations() {
      return _faker.default.random.number(3000);
    },

    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    reference() {
      return ("00000" + _faker.default.random.number()).slice(-6);
    },

    week_average() {
      return _faker.default.finance.amount(0, 3, 1);
    },

    week_comments() {
      return _faker.default.random.number(100);
    },

    week_notations() {
      return _faker.default.random.number(3000);
    }

  });

  _exports.default = _default;
});