define("supplier-portal/models/payment-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    date: _emberData.default.attr('o-date'),
    label: _emberData.default.attr('string'),
    operations: _emberData.default.attr(),
    reference: _emberData.default.attr('string'),
    shop: _emberData.default.belongsTo('shop', {
      inverse: null
    })
  });

  _exports.default = _default;
});