define("supplier-portal/controllers/preauth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    queryParams: ['login', 'otp', 'readonly', 'key'],

    preauth(id) {
      const {
        login,
        otp,
        readonly,
        key
      } = this;

      if (this.get("session.isAuthenticated")) {
        this.session.invalidate().then(() => this.preauth(id));
      } else {
        this.session.authenticate("authenticator:preauth", login, otp, readonly, key).catch(() => {
          this.transitionToRoute("login");
        });
      }
    }

  });

  _exports.default = _default;
});