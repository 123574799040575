define("supplier-portal/controllers/index/purchases/index/pending", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    purchasesController: Ember.inject.controller("index.purchases.index"),
    isReadOnly: Ember.computed.equal("session.data.authenticated.readonly", "1"),
    actions: {
      /**
       * Valid a purchase then go to the next purchase
       */
      validPurchase(reference) {
        const modal = (0, _jquery.default)("#modal").children("div");
        modal.find(".btn-primary").on('click', () => {
          const encodedReference = encodeURIComponent(reference);

          _jquery.default.ajax({
            method: "POST",
            url: "/api/v2/purchases/".concat(encodedReference)
          }).then(() => {
            this.notify.success(this.intl.t("purchases.validation_confirmation"));
            this.store.findRecord("purchase", reference);
            this.purchasesController.set("selection", null);
            this.purchasesController.send("focusLoadedPurchase");
          });

          (0, _jquery.default)(modal).modal("hide");
        });
        modal.find('.modal-title').text(this.intl.t("modal.purchase.validation"));
        modal.modal("show");
      },

      /**
       * Cancel edited lines
       */
      rollbackPurchase(reference) {
        const modal = (0, _jquery.default)("#modal").children("div");
        modal.find(".btn-primary").on('click', () => {
          const purchase = this.store.peekRecord("purchase", reference);
          const length = purchase.get("lines.length");
          let line;

          for (let i = 0; i < length; ++i) {
            line = purchase.get("lines").objectAt(i);
            line.setProperties({
              proposed_price: line.get("purchased_price"),
              proposed_quantity: line.get("purchased_quantity")
            });
          }

          purchase.save().then(purchase => purchase.reload());
          (0, _jquery.default)(modal).modal("hide");
        });
        modal.find('.modal-title').text(this.intl.t("modal.purchase.cancel"));
        modal.modal("show");
      },

      /**
       * Update quantity
       */
      updateQuantity(line, value, defer) {
        const purchaseLine = this.store.peekRecord("purchase-line", line); // Format value before saving

        if (purchaseLine.product.weight_unit) {
          value = Math.round(value * 1000) / 1000; // Round to float with 3 digits
        } else {
          value = Math.round(value); // Round to int
        }

        purchaseLine.set("proposed_quantity", value);
        this.savePurchaseLine(purchaseLine, defer);
      },

      /**
       * Update price
       */
      updatePrice(line, value, defer) {
        const purchaseLine = this.store.peekRecord("purchase-line", line);
        purchaseLine.set("proposed_price", value);
        this.savePurchaseLine(purchaseLine, defer);
      }

    },

    /**
     * Save purchase line
     */
    savePurchaseLine(line, defer) {
      line.set("amount", line.get("proposed_quantity") * line.get("proposed_price"));
      line.save().then(() => {
        defer.resolve();
        this.notify.success(this.intl.t("purchases.update_line_confirmation"));
        this.model.reload();
      }, error => defer.reject(error.message));
    }

  });

  _exports.default = _default;
});