define("supplier-portal/mirage/factories/payment-detail", ["exports", "ember-cli-mirage", "faker", "moment"], function (_exports, _emberCliMirage, _faker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    amount() {
      return _faker.default.random.number({
        max: 9999,
        min: 100
      });
    },

    date() {
      return _faker.default.date.past(5, new Date());
    },

    label() {
      return _faker.default.random.words();
    },

    reference() {
      return _faker.default.random.uuid().substring(0, 6).toUpperCase();
    },

    shop() {
      return _faker.default.random.arrayElement(["001", "003", "004", "005"]);
    },

    operations() {
      const len = _faker.default.random.number({
        max: 19
      });

      const res = [];
      let tag, debitnote, invoice, date;

      for (let i = 0; i < len; ++i) {
        date = _faker.default.date.past(5, new Date());
        tag = _faker.default.random.arrayElement(["invoice", "debitnote"]);
        debitnote = tag === "debitnote" ? "NDD/" + Math.random().toString(10).slice(2) : null;
        invoice = tag === "invoice" ? (0, _moment.default)(date).format("YYYYMMDD") + "003" : null;
        res.push({
          amount: _faker.default.random.number({
            max: 999
          }),
          date: (0, _moment.default)(date).format("YYYY-MM-DD"),
          tag: tag,
          debitnote: debitnote,
          invoice: invoice,
          reference: Math.random().toString(10).slice(2),
          label: _faker.default.random.words()
        });
      }

      return res;
    },

    afterCreate(obj, server) {
      obj.operations.forEach(op => {
        if (op.tag === "invoice") {
          server.create("invoice", {
            reference: op.invoice,
            shop: obj.shop
          });
        }
      });
    }

  });

  _exports.default = _default;
});