define("supplier-portal/controllers/index/accounting/index/pending", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Use global controller
    data: Ember.computed.alias("appCont.data"),
    appCont: Ember.inject.controller('application'),
    sortProperties: ['shop:asc', 'date:asc'],
    sortedPayments: Ember.computed.sort('model.operations', 'sortProperties'),
    groupedShopPayments: (0, _emberGroupBy.default)('sortedPayments', 'shop'),
    groupedPayments: Ember.computed('groupedShopPayments.[]', function () {
      return this.groupedShopPayments.map(function (_this) {
        return {
          shop: _this.value,
          debitnote: _this.items.filter(function (operation) {
            return operation.tag === 'debitnote' || operation.tag === 'debitnote_cancel';
          }),
          invoice: _this.items.filterBy('tag', 'invoice')
        };
      });
    })
  });

  _exports.default = _default;
});