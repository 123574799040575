define("supplier-portal/adapters/purchase-line", ["exports", "supplier-portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForUpdateRecord(id, modelName, snapshot) {
      return this._super(snapshot.record.get("purchase.id"), "purchase");
    }

  });

  _exports.default = _default;
});