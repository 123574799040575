define("supplier-portal/components/o-quality-notation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    rateEvolIsNegative: Ember.computed('val.evolution', function () {
      return this.get('val.evolution') < 0;
    }),
    rateEvolIsPositive: Ember.computed('val.evolution', function () {
      return this.get('val.evolution') > 0;
    })
  });

  _exports.default = _default;
});