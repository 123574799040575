define("supplier-portal/transforms/o-date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      return (0, _moment.default)(serialized).toDate();
    },

    serialize(deserialized) {
      if (!deserialized) {
        return null;
      }

      return (0, _moment.default)(deserialized).format("YYYY-MM-DDTHH:mm:ss");
    }

  });

  _exports.default = _default;
});