define("supplier-portal/models/purchase-line", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    reference: _emberData.default.attr('string'),
    purchased_quantity: _emberData.default.attr('number'),
    purchased_price: _emberData.default.attr('number'),
    amount: _emberData.default.attr('number'),
    package_number: _emberData.default.attr('number'),
    purchase: _emberData.default.belongsTo('purchase'),
    product: _emberData.default.attr(),
    proposed_price: _emberData.default.attr('number'),
    proposed_quantity: _emberData.default.attr('number'),
    formattedProposedPrice: Ember.computed('proposed_price', function () {
      return this._formatAmount(this.proposed_price, "price");
    }),
    formattedPurchasedPrice: Ember.computed('purchased_price', function () {
      return this._formatAmount(this.purchased_price, "price");
    }),
    formattedProposedQuantity: Ember.computed('proposed_quantity', 'product', function () {
      const qty = this.proposed_quantity;
      const unit = this.product.stock_unit;
      const digits = this.product.weight_unit ? 3 : 0;
      return this._formatQuantity(qty, digits, unit);
    }),
    formattedPurchasedQuantity: Ember.computed('purchased_quantity', 'product', function () {
      const qty = this.purchased_quantity;
      const unit = this.product.stock_unit;
      const digits = this.product.weight_unit ? 3 : 0;
      return this._formatQuantity(qty, digits, unit);
    }),
    formattedAmount: Ember.computed('proposed_quantity', 'proposed_price', function () {
      const qty = this.proposed_quantity;
      const price = this.proposed_price;
      return this._formatAmount(qty * price, "amount");
    }),

    _formatAmount(value, format) {
      return this.intl.formatNumber(value, {
        format: format
      });
    },

    _formatQuantity(value, digits, unit) {
      return this.intl.formatNumber(value, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + "\xa0" + unit;
    }

  });

  _exports.default = _default;
});