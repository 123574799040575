define("supplier-portal/templates/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hPpX/rdV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"login_page\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"login_form\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"logo\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"/assets/images/logo.png\"],[10,\"width\",\"100\"],[10,\"height\",\"100\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"login.reset_password\"],null],false],[9],[0,\"\\n\\n    \"],[7,\"form\",false],[3,\"action\",[[23,0,[]],\"changePassword\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-danger text-center\"],[8],[1,[28,\"t\",[\"login.password_requirements\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"autofocus\",\"type\",\"id\",\"class\",\"placeholder\",\"value\"],[\"1\",\"password\",\"password1\",\"form-control\",[28,\"t\",[\"login.password\"],null],[24,[\"password1\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"id\",\"class\",\"placeholder\",\"value\"],[\"password\",\"password2\",\"form-control\",[28,\"t\",[\"login.repeat_password\"],null],[24,[\"password2\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-group text-right\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"btn btn-success\"],[11,\"disabled\",[22,\"invalidPasswords\"]],[10,\"type\",\"submit\"],[8],[1,[28,\"t\",[\"login.valid\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "supplier-portal/templates/password-reset.hbs"
    }
  });

  _exports.default = _default;
});